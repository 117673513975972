<template>
  <v-container class="mt-12 pt-8" style="background:#fafafa;">
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        
        <v-row class="py-1 px-5">
          <v-col cols="12" md="12" class="pt-0" TRANSACTIONS>
            <v-row>
              <v-col cols="12" PANNELS>
                <v-card TRANSACTIONS>
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLEICON>
                            <v-icon size="40" color="primary">gavel</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">الشروط و الأحكام</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">كل ما يجب ان تعرفه بخصوص الشروط و الأحكام</div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12">
                            <v-row class="align-center">
                              <v-col cols="12">
                                <v-card color="grey lighten-4 pa-4" flat>
                                  <v-row class="align-center">
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      مقدمة
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      إننا نهتم بشكل كبير بخصوصية زوار موقعنا، ونتعهد بحمايتها. تشرح هذه السياسة كيفية التصرف في معلوماتك الشخصية.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      بموافقتك على استخدامنا لملفات تعريف الارتباط وفقًا لبنود هذه السياسة عند زيارتك لموقعنا الإلكتروني لأول مرة فإنك تسمح لنا باستخدام ملفات تعريف الارتباط في كل مرة تزور فيها موقعنا.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      جمع المعلومات الشخصية
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      قد يتم جمع وتخزين واستخدام المعلومات الشخصية التالية: معلومات حول جهاز الكمبيوتر الخاص بك بما في ذلك عنوان بروتوكول الإنترنت الخاص بك، والموقع الجغرافي، ونوع المتصفح والإصدار، ونظام التشغيل.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      المعلومات التي تدخلها عند التسجيل في موقعنا الإلكتروني، مثل عنوان بريدك الإلكتروني.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      المعلومات التي تدخلها أثناء استخدام الخدمات على موقعنا.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      أي معلومات شخصية أخرى ترسلها إلينا.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      قبل أن تفصح لنا عن المعلومات الشخصية الخاصة بشخص آخر، ينبغي عليك الحصول على موافقة هذا الشخص على كل من الكشف عن هذه المعلومات الشخصية ومعالجتها وفقًا لهذه السياسة.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      استخدام معلوماتك الشخصية
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      تستخدم المعلومات الشخصية المقدمة إلينا عبر موقعنا الإلكتروني في الأغراض الموضحة في هذه السياسة أو على الصفحات ذات الصلة من الموقع. قد نستخدم معلوماتك الشخصية في الأغراض التالية:
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      تخصيص موقعنا على شبكة الإنترنت ليكون أكثر ملاءمة بالنسبة لك.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      تمكينك من استخدام الخدمات المتاحة على موقعنا.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      رسال إشعارات عبر البريد الإلكتروني التي قمت بطلب إرسالها على وجه التحديد إليك.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      تزويد الأطراف الثالثة بمعلومات إحصائية عن مستخدمينا (غير أن هذه الأطراف الثالثة لن تتمكن من تحديد المستخدمين لتلك المعلومات بحد ذاتهم).
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      إذا قمت بتقديم معلومات شخصية للنشر على موقعنا، فسنقوم بنشر هذه المعلومات واستخدامها بأس شكل آخر وفقًا للترخيص الذي تمنحه لنا.
                                      يمكن استخدام إعدادات الخصوصية الخاصة بك للحد من نشر معلوماتك على موقعنا الإلكتروني ويمكن تعديلها باستخدام عناصر التحكم في الخصوصية على الموقع.
                                      لن نزود أي طرف ثالث، دون موافقتك الصريحة، بمعلوماتك الشخصية لأغراض التسويق المباشر لأي طرف آخر.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      الكشف عن المعلومات الشخصية
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      يمكن أن نقوم بالكشف عن معلوماتك الشخصية لأي من موظفينا أو مسؤولينا أو المستشارين المحترفين أو الوكلاء أو الموردين أو المقاولين من الباطن التابعين لنا القدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه السياسة.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      قد نقوم بالإفصاح عن معلوماتك الشخصية لأي عضو في مجموعة شركاتنا (وهذا يعني الشركات التابعة لنا، والشركة القابضة الأصلية وجميع فروعها) بالقدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه السياسة.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      قد نكشف عن معلوماتك الشخصية:
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      القدر الذي يطلب منا القيام به بموجب القانون
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      فيما يتعلق بأي إجراءات قانونية جارية أو محتملة
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      من أجل إنشاء أو ممارسة أو الدفاع عن حقوقنا القانونية
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      إلى المشتري (أو المشتري المحتمل) لأي عمل أو أصل نبيعه (أو ننوي ذلك).
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      باستثناء ما هو منصوص عليه في هذه السياسة، فإننا لن نقدم معلوماتك الشخصية إلى أطراف ثالثة.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      النقل الدولي للبيانات
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      قد يتم تخزين المعلومات التي نجمعها أو معالجتها أو نقلها بين أي من البلدان التي نعمل فيها لتمكيننا من استخدام المعلومات وفقًا لهذه السياسة.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      د يتم نقل المعلومات التي نجمعها إلى البلدان التالية والتي لا تخضع لقوانين حماية البيانات المكافئة لتلك المعمول بها في المنطقة الاقتصادية الأوروبية: وهذه الدول هي الولايات المتحدة الأمريكية، روسيا، اليابان، الصين، والهند.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      أمن معلوماتك الشخصية
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      نتعهد باتخاذ الاحتياطات التقنية والتنظيمية المعقولة لمنع فقدان معلوماتك الشخصية أو إساءة استخدامها أو تغييرها.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      نتعهد بتخزين جميع المعلومات الشخصية التي تقدمها على خوادمنا الآمنة (المحمية بكلمة مرور وجدار الحماية).
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      تقرّ بموافقتك على هذه السياسة بمعرفتك بأن نقل المعلومات عبر الإنترنت غير آمن بطبيعته، ولا يمكننا ضمان أمن البيانات المرسلة عبر الإنترنت.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      أنت مسؤول عن الحفاظ على سرية كلمة المرور التي تستخدمها للوصول إلى موقعنا. لن نطلب منك كلمة المرور الخاصة بك (إلا عند تسجيل الدخول إلى موقعنا).
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      التعديلات
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      قد نقوم بتحديث هذه السياسة من وقت لآخر عن طريق نشر نسخة جديدة على موقعنا. يجب عليك مراجعة هذه الصفحة من حين لآخر للتأكد من فهمك لأي تغييرات تطرأ على هذه السياسة، وقد نخطرك بالتغييرات التي تطرأ على هذه السياسة عن طريق البريد الإلكتروني أو من خلال نظام المراسلة الخاص على موقعنا.
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2 font-weight-bold`">
                                      ملفات تعريف الارتباط
                                    </v-col>
                                    <v-col cols="12" :class="`${arabic?'arFont':'enFont'} mx-2`">
                                      يستخدم موقعنا ملفات تعريف الارتباط. ملف تعريف الارتباط هو ملف يحتوي على معرف (سلسلة من الحروف والأرقام) يتم إرساله بواسطة خادم الإنترنت إلى متصفح الإنترنت ويتم تخزينه بواسطة المتصفح، ثم يتم إرسال المعرف مرة أخرى إلى الخادم في كل مرة يطلب فيها المتصفح صفحة من الخادم. قد تكون ملفات تعريف الارتباط إما ملفات تعريف ارتباط “دائمة” أو ملفات تعريف ارتباط خاصة بـ “جلسة”: يخزن ملف تعريف الارتباط الدائم بواسطة متصفح الإنترنت ويظل صالحًا حتى تاريخ انتهاء الصلاحية المحدد له، ما لم يتم حذفه من قبل المستخدم قبل تاريخ انتهاء الصلاحية؛ من ناحية أخرى، تنتهي صلاحية ملف تعريف ارتباط الجلسة بنهاية جلسة المستخدم، أي بإغلاق متصفح الإنترنت. لا تحتوي ملفات تعريف الارتباط عادةً على أي معلومات تعرّف عن المستخدم شخصيًا، ولكن قد يتم ربط المعلومات الشخصية التي نخزنها عنك بالمعلومات المخزنة في ملفات تعريف الارتباط التي يتم الحصول عليها منها. { استخدم العبارات المنطبقة نستخدم فقط ملفات تعريف الارتباط للجلسة / فقط ملفات تعريف الارتباط الدائمة / ملفات تعريف الارتباط للجلسة وملفات تعريف الارتباط الدائمة على موقعنا الإلكتروني.
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from '@/components/Search'
export default {
  data() {
    return {
      loading:false,
      dialog:false,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      firstPanel:true,
      secondPanel:false,
      thirdPanel:false,
      selectedPanel:'firstPanel',
      subitems:[
        {
          text:'المعلومات الشخصية',
          icon:'person',
          value:'firstPanel'
        },
        {
          text:'تغيير كلمة المرور',
          icon:'password',
          value:'secondPanel'
        },
        {
          text:'الاحالات',
          icon:'loyalty',
          value:'thirdPanel'
        },
      ]
    }
  },
  methods: {
    showPannel(v){
      this[v] = true
      let all = this.subitems.map(x=>x.value)
      all.filter(x=>x!=v).forEach(x=>this[x] = false)
    },
    setResourceName(){
      this.resourceSettings.resourceName = this.i18n[this.$route.path.replace('/', '')]
    }
  },
  computed:{
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created(){
    this.setResourceName()
  },
}
</script>

<style>

</style>