<template>
  <v-card NotificationPanel :loading="loading">
    <v-card-text class="py-0">
      <v-row>
        <v-col cols="12">
          <v-row class="align-center">
            <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
              <Paging 
                :resourceSettings="resourceSettings" 
                :resource="resource"
                @loadResource="loadResource"
                @pageByNumber="pageByNumber"
              />
            </v-col>
            <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
              <v-expansion-panels focusable>
                <v-expansion-panel v-for="(r, i) in resource.data" :key="'resource-'+i"  style="cursor:pointer;">
                  <v-expansion-panel-header>
                    <v-row class="pa-0 align-center">
                      <v-col cols="12" md="3" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 font-weight-bold`">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{r.user.name}}</span>
                          </template>
                          <div class="text-center">
                            <div :class="`${arabic?'arFont':'enFont'}`">{{r.user.email}}</div>
                          </div>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" md="2" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 font-weight-bold`">
                        {{r.ip}}
                      </v-col>
                      <v-col cols="12" md="2" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 font-weight-bold`">
                        <v-btn x-small text class="success" @click="openLocation(r)">{{i18n.location}}</v-btn>
                      </v-col>
                      <v-col cols="12" md="2" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 font-weight-bold`">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{r.started_at}}</span>
                          </template>
                          <div class="text-center">
                            <div :class="`${arabic?'arFont':'enFont'}`">{{i18n.started_at}}</div>
                          </div>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" md="2" :class="`${arabic?'arFont':'enFont'} pa-0 py-1`">
                        <SurveyQuestionResponseChart :responses="r.survey_question_responses" />
                      </v-col>
                      <v-col cols="12" md="1" :class="`text-center pa-0 py-1`">
                        <v-progress-circular 
                          :size="25"
                          :width="3"
                          color="primary"
                          :value="r.percentageAnswered">
                          <span style="font-size:6px;">{{r.percentageAnswered}}%</span>
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider class="pb-3"></v-divider>
                    <v-row class="pa-0 align-center">
                      <v-col cols="12">
                        <SurveyUserResponsesCards :resource="r" />
                      </v-col>
                      <v-col cols="12">
                        <SurveyUserResponses :responses="r.survey_question_responses" />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    
  </v-card>
</template>

<script>
import Layout from '@/components/Layout'
import Paging from '@/components/Paging'
import SurveyUserResponses from '@/components/SurveyUserResponses'
import SurveyQuestionResponseChart from '@/components/SurveyQuestionResponseChart'
import SurveyUserResponsesCards from '@/components/SurveyUserResponsesCards'
import { format, render, cancel, register } from 'timeago.js';

import axios from 'axios';
const headers = {'Accept': 'application/json'};
const fireapi = 'https://us-central1-asklytic.cloudfunctions.net/main';

export default {
  components: { Paging, Layout, SurveyUserResponses, SurveyQuestionResponseChart, SurveyUserResponsesCards },
  props:['uid'],
  data() {
    return {
      loading:false,
      dialog:false,
      pdfreport:null,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      newDialog:false,
    }
  },
  methods: {
    openLocation(r) {
      const url = `https://maps.google.com/?q=${r.latitude},${r.longitude}`
      window.open(url, '_blank').focus();
    },
    getFormat(d){
      return format(d)
    },
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    clipboardSuccessHandler (){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler () {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async pageByNumber(n){
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.resource = await this.$store.dispatch('getResource', {resource:'/survey-responses-by-user/' + (this.$route.params.uid ? this.$route.params.uid : this.uid), page:`?page=${n}` + this.buildFilter()})
        this.loading = false
      })
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    buildFilter(){
      let filter = ``
      if(this.selectedInstitute) {
        filter += `&filter[account]=${this.selectedInstitute}`
      }
      
      return filter
    },
    async filterUsers(){
      await this.loadResource()
    },
    firstLoad(){
      if(this.user) {
      }
    },
    async loadResource() {
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.resource = await this.$store.dispatch('getResource', {resource:'/survey-responses-by-user/' + (this.$route.params.uid ? this.$route.params.uid : this.uid) + '?' + this.buildFilter()})
        
        this.loading = false
        res()
      })
    },
    async updateResource(r){
      return new Promise(async (res, rej)=>{
        this.loading = true
        // console.log(r)
        await this.$store.dispatch('putResource', {resource:this.$route.path + `/${r.id}`, value:r})
        this.loadResource()
        this.loading = false
      })
    },
    showPannel(v){
      this[v] = true
      let all = this.subitems.map(x=>x.value)
      all.filter(x=>x!=v).forEach(x=>this[x] = false)
    },
    setResourceName(){
      this.resourceSettings.resourceName = this.i18n[this.$route.path.replace('/', '')]
    },
    
  },
  computed:{
    checkDisabled(){
      if(this.user.role == 'master') {
        return false
      } else {
        return this.resource.tokens > 0 ? false : true
      }
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created(){
    this.setResourceName()
    await this.loadResource()
  },
}
</script>

<style>

</style>