<template>
  <Layout>
    <PreInstructions v-if="visible_questions && visible_questions.length && show_instructions && !show_survey" @start="start" :loadin=loading />
    <Survey v-if="show_survey || (visible_questions && !visible_questions.length)" @reload="load" @goToDashboard="goTo('/dashboard')" :loadin=loading />
  </Layout>
</template>
<script>
import Layout from '@/components/Layout'
import PreInstructions from '@/components/PreInstructions'
import Survey from '@/components/Survey'

export default {
  components: { Layout, PreInstructions, Survey },
  data() {
    return {
      loading:false,
      survey_data:null,
      show_instructions:true,
      show_survey:false,
      uid:null,
    }
  },
  methods:{
    start(){
      this.instructions = false
      this.show_survey = true
    },
    
    async loadSurvey(uid){
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.$store.state.survey = await this.$store.dispatch('getResource', {resource:'/survey/' + uid})
        this.loading = false
        res(true)
      })
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    async load(){
      if(!this.$route.params.uid) this.goTo('/dashboard')
      const uid = this.$route.params.uid
      this.uid = uid
      await this.loadSurvey(uid)
    }
  },
  computed:{
    
    visible_questions(){
      return this.$store.state.survey && this.$store.state.survey.survey_questions
      .filter(x=>!x.user_survey_question_response)
    },
    survey(){
      return this.$store.state.survey
    },
  },
  async created(){
    await this.load()
  }
}
</script>