<template>
  <div class="d-print-none">
    <v-app-bar
        :style="`${language=='ar' ? 'border-bottom-left-radius: 0!important;' : null}`"
        elevate-on-scroll
        tile
        fixed
        :color="`${isLogin? 'white' :'primary'}`"
      >
      <v-app-bar-nav-icon color="primary" v-if="isLogin" @click="()=>{
        $vuetify.goTo(0, {
          duration: 0
        })
        drawer = !drawer}">
      </v-app-bar-nav-icon>
      <span 
          v-if="site && site.name && !site.logo" 
          :class="`${language == 'ar' ? 'arFont' : null} ${isLogin? '' : 'white--text'}`"
          @click="goTo('/dashboard')"
          style="cursor:pointer;"
        >{{ site.name.toUpperCase() }}
      </span>
      <img 
        @click="goTo('/dashboard')" 
        v-if="site && site.name && site.logo" 
        :src="`${isLogin? site.logo : site.wlogo}`" 
        alt="site.name" 
        style="width:100px;cursor:pointer;"><span v-if="user && user.account && user.account.logo" class="mx-2 secondary secondary--text">.</span>
        <img 
        class="mx-2"
        @click="goTo('/dashboard')" 
        v-if="user && user.account && user.account.logo" 
        :src="`${user.account.logo}`" 
        alt="site.name" 
        style="height:25px;cursor:pointer;">

      <v-toolbar-title style="width: 40%;" :class="`${arabic?'arFont':'enFont'} px-10 hidden-sm-and-down`">
        <v-text-field
          label="Search"
          dense
          filled
          v-if="isLogin && false"
          v-model="search"
          clearable
          @keypress.enter="fireSearch()"
          hide-details
        >
          <template v-slot:append>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn small :loading="loading" color="primary" v-on="on" @click="fireSearch()">
                  <v-icon>
                    search
                  </v-icon>
                </v-btn>
              </template>
              <span :class="`${arabic?'arFont':'enFont'}`">البحث</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <span v-if="isLogin">
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              color="error"
              overlap
              x-small
              
            >
              <template v-slot:badge>
                <span style="font-size:9px;">{{notifications.filter(x=>!x.read).length}}</span>
              </template>
              <v-icon color="primary" v-bind="attrs" v-on="on">notifications</v-icon>
              
            </v-badge>
          </template>
          <v-card dense>
            <v-card-title :class="`${arabic?'arFont':'enFont'} py-0 text-center`" style="text-transform:uppercase;font-size:9px;">
              {{i18n.notifications}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-0 pt-1" style="width:320px;">
              <Notifications :notifications="notifications" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" text block x-small @click="goTo('/notifications')" :class="`${arabic?'arFont':'enFont'}`">{{i18n.notifications}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </span>
      <span v-if="isLogin">
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" class="add-button" v-bind="attrs" v-on="on">
              <v-icon>person_outline</v-icon>
            </v-btn>
          </template>
          <v-card dense>
            <v-card-title :class="`${arabic?'arFont':'enFont'} py-0 text-center`" style="text-transform:uppercase;font-size:9px;">
              {{i18n.my_account}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-list dense class="px-0">
                <v-list-item class="pa-0 my-0" v-if="false">
                  <v-list-item-title class="pa-0 my-0">
                    <v-btn tile block @click="goTo('/account')" :class="`${arabic?'arFont':'enFont'}`" small depressed text>
                      {{i18n.my_account}}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="pa-0 my-0">
                  <v-list-item-title class="pa-0 my-0">
                    <v-btn tile block @click="signOut()" :class="`${arabic?'arFont':'enFont'}`" small depressed text>
                      {{i18n.logout}}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mx-8">
              <v-btn color="primary" text block x-small>cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </span>
      <v-btn x-small text :dark="!isLogin"  @click="switchLanguage('en')" v-if="language == 'ar'" class="mb-1">
        en
      </v-btn>
      <v-btn x-small text :dark="!isLogin"  v-if="language == 'en'" @click="switchLanguage('ar')" :class="`mb-1 arFont`">
        عربي
      </v-btn>
      
      <v-btn outlined rounded color="white" @click="login()" 
        :class="`${arabic?'arFont':'enFont'} mx-1 px-8 hidden-sm-and-down`" v-if="!isLogin">
        <span>{{i18n.login}}</span>
        <v-icon right>login</v-icon> 
      </v-btn>
      <v-btn rounded color="teal" dark @click="signup()" :class="`${arabic?'arFont':'enFont'} mx-1 px-8 hidden-sm-and-down`" v-if="!isLogin && checkSecret()">
        <span>{{i18n.register}}</span>
        <v-icon right>person_add</v-icon> 
      </v-btn>
      <v-btn  icon rounded color="white" @click="login()" :class="`${arabic?'arFont':'enFont'} mx-1 hidden-md-and-up`" v-if="!isLogin">
        <v-icon>login</v-icon> 
      </v-btn>
      <v-btn  rounded icon color="white" dark @click="signup()" :class="`${arabic?'arFont':'enFont'} mx-1 hidden-md-and-up`" v-if="!isLogin && false">
        <v-icon>person_add</v-icon> 
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :right="language == 'ar'"
      :left="language == 'en'"
      v-if="isLogin"
      app
      dark
      color="#fff"
      clipped
    >
      <v-row class="primary">
        <v-col cols="12" class="text-center">
          <span 
              v-if="site && site.name && !site.logo" 
              :class="`${language == 'ar' ? 'arFont' : null} white--text`"
              @click="goTo('/dashboard')"
              style="cursor:pointer;"
            >{{ site.name.toUpperCase() }}
          </span>
          <img 
            @click="drawer = false" 
            v-if="site && site.name && site.wlogo" 
            :src="site.wlogo" 
            alt="site.name" 
            style="height:33px;cursor:pointer;">
        </v-col>
      </v-row>
      <v-row class="py-1 specialMenu">
        <v-col cols="12"  :class="`${arabic?'arFont':'enFont'} text-left py-1 pt-2`">
          
          
          <v-btn color="primary" x-small @click="drawer = false" text>
            <v-icon color="primary" size="10" right v-if="arabic">arrow_forward_ios</v-icon>
            <v-icon color="primary" size="10" left v-if="!arabic">arrow_back_ios</v-icon>
            <span :class="`${arabic?'arFont':'enFont'}`">
              {{i18n.close_menu}}
            </span>
          </v-btn>
          
        </v-col>
      </v-row>
      <v-list dense nav v-for="(item, i) in items" :key="'item-' + i" class="specialMenu">
        <v-list-item v-if="!item.subs" @click="goTo(item.path)">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title :class="`${arabic?'arFont':'enFont'}`">{{i18n[item.text]}}</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-model="open[i]"
          v-if="item.subs"
          class="specialMenu"
          dark
          :prepend-icon="item.icon"
          append-icon="keyboard_arrow_down"
        >
          <template v-slot:activator class="my-0">
            <v-list-item-title :class="`${arabic?'arFont':'enFont'} my-0 `">{{i18n[item.text]}}</v-list-item-title>
          </template>
          <v-list-item
            v-for="(sub, i) in item.subs"
            :key="i+'sub'"
            link
            @click="goTo(sub.path)"
          >
            
            <v-list-item-title :class="`${arabic?'arFont':'enFont'}`" v-text="i18n[sub.text]"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="sub.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block text @click="signOut()" :class="`${arabic?'arFont':'enFont'}`">
            <span class="primary--text">{{i18n.logout}}</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    
  </div>
</template>

<script>
import {firebase, auth, db, store} from '@/fb';
import Notifications from './Notifications'


export default {
  components: { Notifications },
  data() {
    return {
      drawer: false,
      group:null,
      value:null,
      open: [],
      loading: false,
      menu:false,
      search:null,
    }
  },
  methods: {
    async fireSearch(){
      this.$store.state.search = this.search
    },
    checkSecret() { 
      if(this.$route.query.ss) {
        return true

      } else {
        return false
      }
    },
    login() {
      this.goTo('/login')
    },
    signup() {
      this.goTo('/register')
    },
    openCart(){
      if(this.cart.length) {
        this.$store.dispatch('openCart')
      }
    },
    enLanguage(){
      this.$emit('changeLang', 'en')
    },
    arLanguage(){
      this.$emit('changeLang', 'ar')
    },
    clearStore(){
      this.$store.state.access_token = null
      this.$store.state.user = null
      this.$store.state.uid = null
      this.$store.state.centralLogin = false
      this.$store.state.menu = []
      this.$store.state.globalLoading = false
      this.$store.state.users = []
      this.$store.state.goals = []
      this.$store.state.keyResults = []
      this.$store.state.initiatives = []
      this.$store.state.documents = []
      this.$store.state.notifications = []
      this.$store.state.approvals = []
      this.$store.state.usersLevels = []
      this.$store.state.reports = []
      this.$store.state.othersGoals = []
    },
    async signOut() {
      try {
        await this.$store.dispatch('logout')
      } catch (error) {
        // console.log(error)
      }
      this.clearStore()
    },
    goTo(path) {
      this.$router.push(path).catch(err => {})
    },
    switchLanguage(l) {
      this.$store.state.language = l
      if(l=='ar') {
        this.$vuetify.rtl = true
      } else {
        this.$vuetify.rtl = false
      }
    }
  },
  props: {
    
  },
  watch:{
    isLogin() {}
  },
  computed:{
    
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      // console.log(this.$store.state.centralLogin)
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    online(){
      return this.$store.state.online
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.modelMenu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.modelMenu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    notifications(){
      return this.$store.state.notifications
    },
    miniCart(){
      return this.$store.state.miniCart
    },
    cart(){
      return this.$store.state.cart
    },
    show_cart(){
      return this.$store.state.show_cart
    },
    currency_symbol(){
      return this.$store.state.currency_symbol
    },
    cart_total(){
      return this.$store.state.cart_total
    },
  },
  created () {

  },
  mounted() {
    
  },
}
</script>

<style>
.v-toolbar__extension {
  padding: 0 !important;
}

.specialMenu * {
  color:#EC0B43!important;
}
.specialMenu .v-list-item__icon {
  color:#EC0B43!important;
} 
</style>