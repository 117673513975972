<template>
  <v-container :class="`${arabic?'arFont':'enFont'} black--text mt-12 pt-12`">
    <slot></slot>
  </v-container>
</template>
<script>
export default {
  computed:{
    language(){
      return this.$store.state.language
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
  }
}
</script>