<template>
  <Layout>
    <v-card :loading="loading">
      <v-card-text class="py-0 pb-6">
        <v-row>
          <v-col cols="12">
            <v-row class="align-center">
              <v-col cols="2" class="text-center" TITLE>
                <v-icon size="40" color="primary">quiz</v-icon>
              </v-col>
              <v-col cols="10" class="mt-6">
                <h1 v-if="$store.state.survey.name">
                  {{$store.state.survey.name}}
                </h1>
                <v-btn @click="load" small class="mt-2">reload</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h2>{{i18n.stats}}</h2>
          </v-col>
          <v-col cols="12" class="py-1" v-if="!loading">
            <SurveyResult />
          </v-col>
          <v-col cols="12">
            <h2>{{i18n.details}}</h2>
          </v-col>
          <v-col cols="12" class="py-1" v-if="!loading">
            <v-expansion-panels focusable>
              <v-expansion-panel style="cursor:pointer;">
                <v-expansion-panel-header>
                  {{ i18n.analysis_by_question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pt-6">
                    <v-col>
                      <SurveyQuestionResponsesLayout />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="py-1" v-if="!loading">
            <v-expansion-panels focusable>
              <v-expansion-panel style="cursor:pointer;">
                <v-expansion-panel-header>
                  {{ i18n.user_responses }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pt-6">
                    <v-col>
                      <SurveyUserResponsesLayout />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="load" small class="mt-2">reload</v-btn>
      </v-card-actions>
    </v-card>
  </Layout>
</template>
<script>
import Layout from '@/components/Layout'
import PreInstructions from '@/components/PreInstructions'
import SurveyUserResponsesLayout from '@/components/SurveyUserResponsesLayout'
import SurveyQuestionResponsesLayout from '@/components/SurveyQuestionResponsesLayout'
import SurveyResult from '@/components/SurveyResult'

export default {
  components: { Layout, PreInstructions, SurveyUserResponsesLayout, SurveyQuestionResponsesLayout, SurveyResult },
  data() {
    return {
      loading:false,
      survey_data:null,
      show_instructions:true,
      show_survey:false,
      uid:null,
    }
  },
  methods:{
    start(){
      this.instructions = false
      this.show_survey = true
    },
    
    async loadSurvey(uid){
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.$store.state.survey = await this.$store.dispatch('getResource', {resource:'/survey-results/' + uid})
        this.loading = false
        res(true)
      })
    },
    
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    async load(){
      if(!this.$route.params.uid) this.goTo('/dashboard')
      const uid = this.$route.params.uid
      this.uid = uid
      await this.loadSurvey(uid)
    }
  },
  computed:{
     i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    language(){
      return this.$store.state.language
    },
    visible_questions(){
      return this.$store.state.survey && this.$store.state.survey.survey_questions
      .filter(x=>!x.user_survey_question_response)
    },
    survey(){
      return this.$store.state.survey
    },
  },
  async created(){
    await this.load()
  }
}
</script>