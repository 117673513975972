<template>
  <v-row v-if="question">
    <v-col cols="12" class="pt-12 text-center">
      <h1 style="line-height: 1.2;">{{question.label}}</h1>
    </v-col>
    <v-col cols="12" class="pb-12">
      <span v-if="question.type === 'multiple'">
        <v-select 
          :label="question.label"
          :items="question.survey_question_options"
          outlined
          v-model="value"
          item-text="label"
          item-value="id"
        ></v-select>
      </span>
      <span v-if="question.type === 'text'">
        <v-text-field
          :label="question.label"
          outlined
          v-model="value"
        ></v-text-field>
      </span>
      <span v-if="question.type === 'rate'" class="text-center py-5">
        <v-rating
          empty-icon="star_outline"
          full-icon="star"
          class="hidden-sm-and-down"
          length="5"
          size="80"
          v-model="value"
        ></v-rating>
        <v-rating
          empty-icon="star_outline"
          full-icon="star"
          class="hidden-md-and-up"
          length="5"
          size="40"
          v-model="value"
        ></v-rating>
      </span>
      <div v-if="question.type === 'rate'" class="text-center mt-3 py-5">
        <h1>{{rates[value]}}</h1>
      </div>
    </v-col>
    <v-col cols="12" class="hidden-sm-and-down">
      <v-row>
        <v-col cols="1">
        </v-col>
        <v-col cols="2" v-for="(rate, r) in rates" :key="'rate-'+r">
          {{rate}}
        </v-col>
        <v-col cols="1">
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn 
        x-large
        block
        :disabled="!value"
        @click="action('nextQuestion')"
        color="primary"
      >
        {{i18n.next_question}}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props:['question', 'questionsCount'],
  data(){
    return {
      value:null,
      rates:{
        5:"Excellent",
        4:"V Good",
        3:"Good",
        2:"Acceptable",
        1:"Not Satisfied",


      }
    }
  },
  methods:{
    action(action) {
      this.$emit(action, this.value)
      this.value = null
    }
  },
  computed:{
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    language(){
      return this.$store.state.language
    },
    count(){
      return this.$props.questionsCount
    }
  },
}
</script>>
