<template>
  <v-container class="mt-12 pt-8" style="background:#fafafa;">
    <v-row class="py-1 px-5 fill-height" style="background-color:#f6f8fa;">
        <div >
          <form class="four-oh-four-form">
            <input type="text" class="404-input">
          </form>

          <div class="terminal">
              <p class="prompt">Oh you broke the website, The page you requested cannot be found right now.</p>
              <p class="prompt output new-output"></p>
          </div>
        </div>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
@-webkit-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
body {
  background-color: black;
}

.four-oh-four {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  background-color: black;
  transition: opacity 300ms ease-out;
  background-position: center center;
  background-repeat: no-repeat;
}
.four-oh-four .dJAX_internal {
  opacity: 0;
}
.four-oh-four form, .four-oh-four input {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: black;
}

.terminal {
  position: relative;
  padding: 4rem;
}
.terminal .prompt {
  color: #1ff042;
  display: block;
  font-family: "AndaleMono", monospace;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.15em;
  white-space: pre-wrap;
  text-shadow: 0 0 2px rgba(31, 240, 66, 0.75);
  line-height: 1;
  margin-bottom: 0.75em;
}
.terminal .prompt:before {
  content: "> ";
  display: inline-block;
}
.terminal .new-output {
  display: inline-block;
}
.terminal .new-output:after {
  display: inline-block;
  vertical-align: -0.15em;
  width: 0.75em;
  height: 1em;
  margin-left: 5px;
  background: #1ff042;
  box-shadow: 1px 1px 1px rgba(31, 240, 66, 0.65), -1px -1px 1px rgba(31, 240, 66, 0.65), 1px -1px 1px rgba(31, 240, 66, 0.65), -1px 1px 1px rgba(31, 240, 66, 0.65);
  -webkit-animation: cursor-blink 1.25s steps(1) infinite;
  -moz-animation: cursor-blink 1.25s steps(1) infinite;
  animation: cursor-blink 1.25s steps(1) infinite;
  content: "";
}

.kittens p {
  letter-spacing: 0;
  opacity: 0;
  line-height: 1rem;
}

.kitten-gif {
  margin: 20px;
  max-width: 300px;
}

.four-oh-four-form {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
}
</style>>
