<template>
  <span @contextmenu="show">
    <v-app >
      <Navbar/>
      <v-content style="background:#fafafa;" class="fill-height">
          <router-view
            @snackbarNotification="getSnack"
          ></router-view>
      </v-content>
      <Footer />

      <v-snackbar v-model="snackbar" :color="type" :timeout="4000" bottom left>
        <span :class="`${ arabic ? 'arFont' : '' }`">{{ snackbarContent }}</span>
        <v-btn color="white" dark text small @click="snackbar = false">
          <v-icon size="12">close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-app>
  </span>
</template>

<script>
import Footer from '@/components/footer'
import Navbar from './components/Navbar'

import axios from 'axios';
import { getRandomInt, randomString, getCDate, check, sleep } from '@/customFunction';
const headers = {'Accept': 'application/json'};

export default {
  components: { Navbar, Footer },
  name: 'App',
  data () {
    return {
      loading:false,
      transitionName:null,
      snackbar:false,
      snackbarContent:'',
      type:null,
      pinname:null,
      fieldTypes:{
        password:'text',
      },
      rightmenu:false,
      x:0,
      y:0,
    }
  },
  methods: {
    show(e) {
      // e.preventDefault()
      // this.rightmenu = false
      // this.x = e.clientX
      // this.y = e.clientY
      // this.$nextTick(() => {
      //   this.rightmenu = true
      // })
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode != 45 && charCode != 46 &&
      !(charCode >= 48 && charCode <= 57)) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    getName() {
      return this.pinname = randomString(28, '#Aa')
    },
    setCookie(name, value, days) {
      var expires = "";
      var maxAge = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        maxAge = "; max-age=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    eraseCookie(name) {   
      document.cookie = name+'=; Max-Age=-99999999;';  
    },
    getSnack (text, type) {
      this.snackbar = true
      this.snackbarContent = text
      this.type = type
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    async loadScrollStyle(theme){
      // let b = `
      //   ::-webkit-scrollbar-track {
      //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.22);
      //       background-color: ${theme.secondary};
      //   }

      //   ::-webkit-scrollbar {
      //     width: 10px;
      //     background-color: ${theme.primary};
      //   }

      //   ::-webkit-scrollbar-thumb {
      //     background-color: ${theme.primary};
      //     border-radius:  0 0 10px 10px; 
      //   }`
      //   let a = document.createElement('style')
      //   a.innerHTML = b
      //   document.head.append(a)
    },
    getNotifications(){
      // setInterval(x=>{
      //   if(this.$store.state.centralLogin) {
      //     this.$store.dispatch('checkAuth')
      //     this.$store.dispatch('getNotifications')
      //   }
      // }, 5 * 60 * 1000)
      // setInterval(x=>{
      //   if(this.$store.state.centralLogin) {
      //   }
      // }, 5* 60 * 1000)
    },
    async checkState(){
      return new Promise(async (res, rej)=>{
        let noGate = ['home', 'login', 'register', 'toc', 'verify']
        let access_token = this.getCookie('_ASK_LYTIC')
        console.log('app check status', access_token)
        if(access_token) {
          this.$store.state.access_token = access_token
          let a = await this.$store.dispatch('getUser')
          if(!a || a.error) {
            if(!noGate.includes(this.$route.name)) {
              this.goTo('/home')
              res(false)
            }
          } else {
            this.$store.state.user = a
            this.$store.state.centralLogin = true
            // this.$store.dispatch('getNotifications')
            // console.log('last', localStorage.last)
            if(localStorage.last) {
              this.goTo(localStorage.last)
            }
            // if(a.role == 'admin')  {
            //   this.goTo('/home')
            // } else if(a.role == 'superadmin')  {
            //   this.goTo('/home')
            // } else {
            //   this.goTo('/home')
            // }
            this.getNotifications()
            res(true)
          }
        } else if(noGate.includes(this.$route.name)){
        } else if(!noGate.includes(this.$route.name)){
          this.goTo('/home')
          res(false)
        }
      })
    },
  }, 
  computed: {
    api(){
      return this.$store.state.settings.apiBase
    },
    getLanguage(){
      if(this.$store.state.language == 'ar') {
        this.$vuetify.rtl = true
      }
      return this.$store.state.language
    },
    site(){
      if(this.$store.state.site) {
        let site = this.$store.state.site
        this.$vuetify.theme.currentTheme.primary = site.theme.light.primary
        this.$vuetify.theme.currentTheme.secondary = site.theme.light.secondary
        this.$vuetify.theme.currentTheme.accent = site.theme.light.accent
        this.$vuetify.theme.currentTheme.error = site.theme.light.error
        this.$vuetify.theme.currentTheme.info = site.theme.light.info
        this.$vuetify.theme.currentTheme.success = site.theme.light.success
        this.$vuetify.theme.currentTheme.warning = site.theme.light.warning
        this.loadScrollStyle(site.theme.light)
        return site
      } else {
        return {}
      }
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
  },
  watch: {
    async isLogin(login){
      await this.checkState()
    },
    getLanguage(){},
    site(){},
    $route (to, from){
      localStorage.last = to.path
      let path = to.path
      let user = {...this.$store.state.user}
      let role = user.role
      
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
  async created() {
    var w = window.location.origin
    var base_url = w.includes('localhost') || w.includes('firebase') || w.includes('web.app') || w.includes('asklytic.com') ? 'https://api.asklytic.com' : window.location.origin
    // console.log(base_url)
    this.$store.dispatch('setSettingsBase', {base:base_url})
    let a = await this.checkState()
  },
  async mounted(){
    
  },
  destroyed(){
  }
}
</script>
<style>
/*  */
.RTL {
  direction: rtl !important;
  
}
.arFont {
  font-family: 'Tajawal', sans-serif !important;
  letter-spacing: unset !important;
}
.enFont {
  font-family: 'Bricolage Grotesque', sans-serif;
}
.arTitle {
  font-family: 'Tajawal', sans-serif !important;
  letter-spacing: unset !important;
  font-size: 1.25rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
}
.solidCard {
  background-image: url("/resume_back_2.png");
  background-size: cover;
}
</style>
