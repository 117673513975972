import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'
import "firebase/performance";
import "firebase/firestore";


// Initialize Firebase
var config = {
  apiKey: "AIzaSyA_xWXKRsA_0lg_xhptlUWg081mX8lnRLc",
  authDomain: "asklytic.firebaseapp.com",
  databaseURL: "https://asklytic-default-rtdb.firebaseio.com",
  projectId: "asklytic",
  storageBucket: "asklytic.appspot.com",
  messagingSenderId: "596679591687",
  appId: "1:596679591687:web:ce2cbd0b02f1e642dda7a2",
  measurementId: "G-23D0NDS3YN"
};
firebase.initializeApp(config);

const db = firebase.database();
const store = firebase.firestore();
const auth = firebase.auth();
const storageRef = firebase.storage().ref();
const perf = firebase.performance();

export {
  firebase,
  db,
  auth,
  store,
  perf,
  storageRef
};
