<template>
  <!-- <v-card class="px-3" flat :loading="loading">
    <v-card-text>
      
      
    </v-card-text>
  </v-card> -->
  <v-row class="px-3 align-center">
    <v-col cols="12" :md="`${resourceSettings.create?'6':'9'}`" :class="`${$vuetify.breakpoint.md ? 'pa-0' : 'py-1'}`" v-if="resourceSettings.search">
      <v-text-field
          :label="i18n.search"
          outlined
          hide-details
          dense
          :name="i18n.search"
          @keypress.enter="searchResource()"
          :append-icon="`${search? 'close':''}`"
          @click:append="()=>{search=null;loadResource();}"
          v-model="search"
          :class="`${arabic?'arFont':'enFont'}`"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="3" :class="`${$vuetify.breakpoint.md ? 'pa-0' : 'py-1'} px-1`" v-if="resourceSettings.search">
      <v-btn color="primary" :disabled="!search" @click="searchResource()" block :class="`${arabic?'arFont':'enFont'}`">
        {{i18n.search}}
      </v-btn>
    </v-col>
    <v-col cols="12" :md="`${resourceSettings.search? '3':'12'}`" 
      :class="`${$vuetify.breakpoint.md ? 'pa-0' : 'py-1'} px-1`" v-if="resourceSettings.create">
      <v-btn color="primary" @click="create()" block :class="`${arabic?'arFont':'enFont'}`">
        {{i18n.new}}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      search:null
    }
  },
  props:['loading', 'resourceSettings'],
  methods:{
    loadResource() {
      this.$emit('loadResource')
    },
    searchResource() {
      this.$emit('searchResource', this.search)
    },
    create() {
      this.$emit('create')
    }
  },
  computed:{
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
  }
}
</script>

<style>

</style>