let map = {
    nodes:[
			{
					text:`خريطة الابتكار`,
					'fx': 176.083777747024,
					'fy': -665.1641376795345,
			},
			// MAIN NODES
			{
					text:`دراسة الافكار`,
					'fx': 31.295789077886795,
					'fy': -502.9885666076692,
			},
			{
					text:`توليد الافكار`
			},
			{
					text:`مراقبة الجودة`
			},
			{
					text:`الرعاية والتدريب`
			},
			{
					text:`قيادة المشاريع`
			},
			/// `دراسة الافكار`
			{
					text:`تقييم الافكار`
			},
			{
					text:`اقتراح الافكار`
			},
			{
					text:`اختيار الافكار`
			},
			{
					text:`استخدام مفرط لنقاط القوة في الدراسة`
			},
			/// `توليد الافكار`
			{
					text:`متى يكون الحماس`
			},
			{
					text:`حواجز توليد الافكار`
			},
			{
					text:`منطلق توليد الافكار`
			},
			{
					text:`احداث الافكار`
			},
			{
					text:`استخدام مفرط لنقاط القوة في التوليد`
			},
			/// `مراقبة الجودة`
			{
				text:`الرد على المطالبات`
			},
			{
				text:`التحسينات`
			},
			{
				text:`نهج الاحداث`
			},
			{
				text:`نهج الادارة`
			},
			{
				text:`السلوك`
			},
			{
				text:`استخدام مفرط لنقاط القوة في المراقبة`
			},
			/// `الرعاية والتدريب`
			{
				text:`التعامل مع المدراء واصحاب المصلحة`
			},
			{
				text:`السلوك للداعمين`
			},
			{
				text:`تطوير الاخرين`
			},
			{
				text:`التغذية الراجعة للأخرين`
			},
			{
				text:`دعم الاخرين`
			},
			{
				text:`استخدام مفرط لنقاط القوة في الرعاية`
			},
			/// `قيادة المشاريع`
			{
				text:`اخذ مبادرة القيادة`
			},
			{
				text:`التخطيط`
			},
			{
				text:`التنظيم`
			},
			{
				text:`التطبيق`
			},
			{
				text:`مراقبة النهج`
			},
			{
				text:`استخدام مفرط لنقاط القوة في القيادة`
			},
    ],
    connections:[
			{
					source:`خريطة الابتكار`,
					target:`دراسة الافكار`
			},
			{
					source:`خريطة الابتكار`,
					target:`توليد الافكار`
			},
			{
					source:`خريطة الابتكار`,
					target:`مراقبة الجودة`
			},
			{
					source:`خريطة الابتكار`,
					target:`الرعاية والتدريب`
			},
			{
					source:`خريطة الابتكار`,
					target:`قيادة المشاريع`
			},
			//// `دراسة الافكار`
			{
					source:`دراسة الافكار`,
					target:`تقييم الافكار`
			},
			{
					source:`دراسة الافكار`,
					target:`اقتراح الافكار`
			},
			{
					source:`دراسة الافكار`,
					target:`اختيار الافكار`
			},
			{
					source:`دراسة الافكار`,
					target:`استخدام مفرط لنقاط القوة في الدراسة`
			},
			/// `توليد الافكار`
			{
					source:`توليد الافكار`,
					target:`متى يكون الحماس`
			},
			{
					source:`توليد الافكار`,
					target:`حواجز توليد الافكار`
			},
			{
					source:`توليد الافكار`,
					target:`منطلق توليد الافكار`
			},
			{
					source:`توليد الافكار`,
					target:`احداث الافكار`
			},
			{
					source:`توليد الافكار`,
					target:`استخدام مفرط لنقاط القوة في التوليد`
			},
			/// `مراقبة الجودة`
			{
				source:`مراقبة الجودة`,
				target:`الرد على المطالبات`
			},
			{
				source:`مراقبة الجودة`,
				target:`التحسينات`
			},
			{
				source:`مراقبة الجودة`,
				target:`نهج الاحداث`
			},
			{
				source:`مراقبة الجودة`,
				target:`نهج الادارة`
			},
			{
				source:`مراقبة الجودة`,
				target:`السلوك`
			},
			{
				source:`مراقبة الجودة`,
				target:`استخدام مفرط لنقاط القوة في المراقبة`
			},
			/// `الرعاية والتدريب`
			{
				source:`الرعاية والتدريب`,
				target:`التعامل مع المدراء واصحاب المصلحة`
			},
			{
				source:`الرعاية والتدريب`,
				target:`السلوك للداعمين`
			},
			{
				source:`الرعاية والتدريب`,
				target:`تطوير الاخرين`
			},
			{
				source:`الرعاية والتدريب`,
				target:`التغذية الراجعة للأخرين`
			},
			{
				source:`الرعاية والتدريب`,
				target:`دعم الاخرين`
			},
			{
				source:`الرعاية والتدريب`,
				target:`استخدام مفرط لنقاط القوة في الرعاية`
			},
			/// `قيادة المشاريع`
			{
				source:`قيادة المشاريع`,
				target:`اخذ مبادرة القيادة`
			},
			{
				source:`قيادة المشاريع`,
				target:`التخطيط`
			},
			{
				source:`قيادة المشاريع`,
				target:`التنظيم`
			},
			{
				source:`قيادة المشاريع`,
				target:`التطبيق`
			},
			{
				source:`قيادة المشاريع`,
				target:`مراقبة النهج`
			},
			{
				source:`قيادة المشاريع`,
				target:`استخدام مفرط لنقاط القوة في القيادة`
			},
    ]
}


export {
  map
};