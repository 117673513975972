<template>
  <v-container class="mt-12 pt-8" style="background:#fafafa;">
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        
        <v-row class="py-1 px-5">
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="px-5" PANNELS>
                <v-card NotificationPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row class="align-center">
                      <v-col cols="12" LOADING v-if="!loading && user && user.account && user.account.logo" class="text-center my-10">
                        <img 
                          v-if="site && site.name && site.logo" 
                          :src="`${isLogin? site.logo : site.wlogo}`" 
                          alt="site.name" 
                          style="width:100px;cursor:pointer;">
                          <span v-if="user && user.account && user.account.logo" class="mx-2 secondary secondary--text">.</span>
                          <img 
                          class="mx-2"
                          v-if="user && user.account && user.account.logo" 
                          :src="`${user.account.logo}`" 
                          alt="site.name" 
                          style="height:25px;cursor:pointer;">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'} text-center`" TITLE>
                            {{i18n.assessment_progress}}
                          </v-col>
                          <v-col cols="12" TITLE>
                            <v-progress-linear :value="progress" height="15" color="primary"></v-progress-linear>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                        <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" QUESTION v-if="!loading">
                            <v-row class="align-center">
                              <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
                                {{visible+1}}
                              </v-col>
                              <v-col cols="10" :class="`${arabic?'arFont':'enFont'}`" style="font-size:22px;font-weight:bold;">
                                {{questions[visible][language]}}
                              </v-col>
                              <v-col cols="12" class="text-center py-5">
                                <v-rating
                                  empty-icon="star_outline"
                                  full-icon="star"
                                  half-increments
                                  class="hidden-sm-and-down"
                                  background-color="grey"
                                  length="5"
                                  size="80"
                                  v-model="questions[visible].answer"
                                ></v-rating>
                                <v-rating
                                  empty-icon="star_outline"
                                  full-icon="star"
                                  half-increments
                                  class="hidden-lg-and-up"
                                  background-color="grey"
                                  length="5"
                                  size="40"
                                  v-model="questions[visible].answer"
                                ></v-rating>
                              </v-col>
                              <v-col cols="12" :class="`${arabic?'arFont':'enFont'} text-center secondary--text`" style="font-size:22px;font-weight:bold;">
                                {{values[language][parseInt(questions[visible].answer)]}}
                              </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" :class="`${arabic?'arFont':'enFont'} text-center py-6`" QUESTION v-if="loading">
                          <v-progress-circular indeterminate color="primary" size="220" :class="`${arabic?'arFont':'enFont'}`">
                            
                            {{i18n.building_next_question}}
                          </v-progress-circular>
                        </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions v-if="!loading">
                    <v-btn 
                      :class="`${arabic?'arFont':'enFont'}`" 
                      x-large
                      block
                      :loading="loading"
                      v-if="visible < questions.length - 1"
                      @click="nextQuestion()"
                      color="secondary"
                      :disabled="!questions[visible].answer || loading"
                    >
                    {{i18n.next}}
                    </v-btn>

                    <v-btn 
                      :class="`${arabic?'arFont':'enFont'}`" 
                      x-large
                      block
                      :loading="loading"
                      v-if="visible >= questions.length - 1"
                      @click="nextQuestion()"
                      color="secondary"
                      :disabled="!questions[visible].answer || loading"
                    >
                    {{i18n.end_and_show_results}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
const headers = {'Accept': 'application/json'};

export default {
  components: {},
  data() {
    return {
      loading:false,
      dialog:false,
      open:[],
      toc:false,
      values:{
        en:{
          1:`To almost no extent`,
          2:`To a slight extent`,
          3:`To a moderate extent`,
          4:`To a great extent`,
          5:`To a very great extent`,
        },
        ar:{
          1:`لا تقريبا`,
          2:`إلى حد ما`,
          3:`إلى حد معتدل`,
          4:`إلى حد كبير`,
          5:`إلى حد كبير جدا`,
        },
      },
      quiz:[],
      visible:0,
      prev_progress:0
    }
  },
  methods: {
    
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    async storeAnswer(){
      return new Promise(async (res, rej)=>{
        let s = await this.$store.dispatch('postResource', {resource:'/answers', value:this.questions[this.visible]})
        console.log(s)
        res(true)
      })
    },
    async nextQuestion(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        await this.storeAnswer()
        if(this.visible >= this.questions.length - 1) {
          this.goTo('/results')
        } else {
          this.visible++
        }
        this.loading = false
        res(true)
      })
    },
    async loadHome(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        let old = await this.$store.dispatch('getResource', {resource:'/user-answers'})
        console.log(old)
        let done = old.count >= 69 ? true : false
        if(done) {
          this.goTo('/results')
        } else {
          let qs =  await this.$store.dispatch('getResource', {resource:'/questions'})
          if(qs.filter(x=>!old.all.map(y=>y.question_id).includes(x.id)).length) {
            this.quiz = qs.filter(x=>!old.all.map(y=>y.question_id).includes(x.id))
            this.prev_progress = qs.filter(x=>old.all.map(y=>y.question_id).includes(x.id)).length
            try {
              if(this.prev_progress == 0) {
                gtag('event', `assessment_start`);
              } else {
                gtag('event', `assessment_continue`);
              }
            } catch (error) {
              // console.log(error)
            }
            console.log(this.quiz)
          } else {
            this.goTo('/results')
          }
          this.loading = false
        }
        try {
          gtag('event', `assessment_visit`);
        } catch (error) {
          // console.log(error)
        }
        res(true)
      })
    }
    
  },
  computed:{
    progress(){
      return (this.visible + this.prev_progress)/69 * 100
    },
    questions(){
      return this.quiz
    },
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
  watch:{
    
  },
  async created(){
    await this.loadHome()
  },
}
</script>

<style>
.flagBtn {
  height: 80px !important;
}
</style>