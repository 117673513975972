<template>
  <v-container class="mt-12 pt-8" style="background:#fafafa;">
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        
        <v-row class="py-1 px-5">
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="px-5" PANNELS>
                <v-card NotificationPanel :loading="loading" v-if="!error">
                  <v-card-text class="py-0" id="print">
                    <v-row class="align-center">
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">verified_user</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">
                              
                              {{ i18n.assessment_result }}
                            </div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">
                              
                              {{ i18n.assessment_result_map }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" LOADING v-if="!loading && resource && resource.account && resource.account.logo" class="text-center my-10">
                        <img 
                          v-if="site && site.name && site.logo" 
                          :src="`${isLogin? site.logo : site.wlogo}`" 
                          alt="site.name" 
                          style="width:100px;cursor:pointer;">
                          <span v-if="user && resource.account && resource.account.logo" class="mx-2 secondary secondary--text">.</span>
                          <img 
                          class="mx-2"
                          v-if="user && resource.account && resource.account.logo" 
                          :src="`${resource.account.logo}`" 
                          alt="site.name" 
                          style="height:25px;cursor:pointer;">
                      </v-col>
                      <v-col cols="12" v-if="resource && resource.account && resource.account.name">
                        <v-row class="align-center">
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'} text-center primary--text`" style="font-size:26px;">
                            {{ resource.account.name }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="6" :class="`${arabic?'arFont':'enFont'} text-center primary--text`" style="font-size:26px;">
                            
                            {{ i18n.final_result }}
                          </v-col>
                          <v-col cols="6" :class="`${arabic?'arFont':'enFont'} text-center secondary--text`" style="font-size:26px;" v-if="!loading">
                            {{result.total}} {{ i18n.point }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="2" class="forceArabic">
                      </v-col>
                      <v-col cols="12" md="8" class="forceArabic">
                        <innovationRadar :data="chartData" />
                      </v-col>
                      <v-col cols="12" md="2" class="forceArabic">
                      </v-col>
                      <v-col cols="12" class="forceArabic" v-if="false">
                        <mindmap
                          :nodes="nodes"
                          :connections="connections"
                          :editable="true"
                        />
                      </v-col>
                      <v-col cols="12" class="forceArabic" v-if="result && result.map">
                        <img :src="`/images/results/${language}/map_r_${result.map}.png`" width="97%" alt="">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" TIME>
                            <v-row class="align-center">
                              <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
                              </v-col>
                              <v-col cols="10" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                                
                              </v-col>
                            </v-row>
                          </v-col>
                          
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-dialog
                          v-model="dialog"
                          scrollable 
                          persistent :overlay="false"
                          max-width="800px"
                          transition="dialog-transition"
                        >
                          <v-card>
                            <v-card-actions>
                              <v-btn color="error" :class="`${arabic?'arFont':'enFont'}`" text @click="dialog = false">{{ i18n.close }}</v-btn>
                            </v-card-actions>
                            <iframe v-if="dialog" id="pdfreport" :src="pdfreport" height="800px" frameborder="0"></iframe>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-print-none">
                    <v-btn 
                      :class="`${arabic?'arFont':'enFont'}`" 
                      x-large
                      block
                      :loading="loading"
                      @click="()=>{dialog = true;reportHTMLif();}"
                      color="secondary"
                    >
                      {{ i18n.detailed_report }}
                    
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card NotificationPanel :loading="loading" v-if="error">
                  <v-card-text class="pa-3" id="print">
                    <v-alert type="error" :value="true" :class="`${arabic?'arFont':'enFont'}`">
                      {{i18n.no_user_finished}}
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import innovationRadar from '@/components/innovationRadar'
import {map} from '@/map'
import axios from 'axios';
import { randomString, sleep } from '@/customFunction';
import { firebase, store, db, auth, storageRef } from '@/fb';
const headers = {'Accept': 'application/json'};
export default {
  components: {innovationRadar},
  data() {
    return {
      loading:false,
      dialog:false,
      open:[],
      result:null,
      ...map,
      chartData:{
        labels: this.labels,
        // labels: [
        //     'Creativity', // 1
        //     'Problem-Solving', // 1
        //     'Continuous Improvement Skills', // 1
        //     'Risk Assessment', // 2
        //     'Risk-Taking Skills', // 2
        //     'Relationshipâ€“Building', // 3
        //     'Communication Skills', // 3
        //     'Implementation Skills' // 4
        // ],
        datasets: [{
            label: 'خريطة الابتكار',
            data: [20, 10, 18, 30, 22, 13, 28, 15],
            backgroundColor: 'rgba(2, 61, 102, 0.75)', // 'rgba(255, 99, 132, 0.8)',
            borderColor: 'rgb(2, 61, 102)',
            pointBackgroundColor: '#023d66',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
        }],
      },
      uploadProgress:false,
      uploadFiled:null,
      file:null,
      pdfreport:null,
      pdfreporthtml:null,
      resource:{},
      error:false,
    }
  },
  methods: {
    printReport() {
      window.frames["pdfreprot"].focus();
      window.frames["pdfreprot"].contentWindow.print();
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    print(){
      window.print();
    },
    loadFileImages(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('radar/EVOTIK-INNO-' + randomString(16, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					  this.uploadProgress = true
          }, (error) =>{
          // console.log(error);

        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) =>{
					this.uploadProgress = false
          let hash = this.$route.params.hash
          let utmc = this.user.hash
          let r = await this.$store.dispatch('postResource', {resource:`/store-radar-account${hash ? '/' + hash : ''}?uic=${utmc}`, value:{url:downloadURL}})
          console.log(downloadURL, r)
        });
      });
    },
    async reportHTMLif() {
      if(this.pdfreporthtml){
        await sleep(1000)
        var iframe = document.getElementById('pdfreport'),
        iframedoc = iframe.contentDocument || iframe.contentWindow.document;
        iframedoc.body.innerHTML = this.pdfreporthtml;
      }
    },
    async loadHome(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        let hash = this.$route.params.hash
        let utmc = this.user.hash
        let r = await this.$store.dispatch('getResource', {resource:`/report_final${hash ? '/' + hash : ''}?uic=${utmc}`})
        if(r.error) {
          this.error = true
        }
        this.resource = r
        console.log(r)
        this.result = r
        this.chartData.datasets[0].data = r.result
        let labels = {
          ar:[
            'الاستمرار في تطوير المهارات' , //
            'الابداع', //
            'حل المشاكل', //
            'تقييم المخاطر',
            'المخاطرة',
            'بناء العلاقات' , 
            'التواصل',
            'التطبيق'
          ],
          en:[
            'Continuous Skill Improvement' , //
            'Creativity', //
            'Problem Solving', //
            'Risk Assessment',
            'Risk Taking',
            'Relationship-Building' , 
            'Communication Skills',
            'Implementation Skills'
          ],
        }
        this.chartData.labels = labels[this.language]
        
        await sleep(1000)
        console.log('started_saving_radar')
        let canvas = document.getElementById('radar-chart')
        var image = canvas.toBlob((image) => {
          this.loadFileImages(image, 'image/png', '.png')
        },"image/png", 1);
        
        if(!hash) {
          this.pdfreport = `https://innovation.evotik.com/b/public/api/v1/report_final?pdf=true&uic=${utmc}`
        } else {
          this.pdfreport = `https://innovation.evotik.com/b/public/api/v1/report_final/${hash}?pdf=true`
        }
        try {
          gtag('event', `result_view`);
        } catch (error) {
          // console.log(error)
        }
        this.loading = false
        res(true)
      })
    }
    
  },
  computed:{
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
  watch:{
    
  },
  async created(){
    await this.loadHome()
  },
}
</script>

<style>
.forceArabic * {
  font-family: 'Tajawal', sans-serif !important;
  letter-spacing: unset !important;
  font-size: 0.9rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
}
.mindmap-subnode-group *,
.mindmap-subnode-group {
  white-space: nowrap!important;
  font-size: 0.5rem!important;
}
</style>