<template>
  <apexchart height="30" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
  components: {  },
  props:['responses'],
  data() {
    return {
      chartOptions:{
        chart: {
          type: 'area',
          height: 30,
          sparkline: {
            enabled: true
          },
        },
        stroke: {
          curve: 'straight',
          width: [2]
        },
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          },
          marker: {
            show: false
          }
        },
        fill: {
          opacity: 0.8,
        },
        xaxis: {
          crosshairs: {
            width: 1
          },
        },
        yaxis: {
          min: 0
        },
        colors: ['#ec0b43'],
      }

    }
  },
  computed:{
    series(){
      return [{
        data: this.responses.map(x=>x.rating),
        name: "Rating",
      }]
    }
  }
}
</script>