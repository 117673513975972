import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueClipboard from 'vue-clipboard2'
import Vuelidate from 'vuelidate';
import {db} from  '@/fb';
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'
import VueCountdown from '@chenfengyuan/vue-countdown';
import VuePapaParse from 'vue-papa-parse'
import VueMindmap from 'vue-mindmap'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-mindmap/dist/vue-mindmap.css'
import VueApexCharts from 'vue-apexcharts'

Vue.use(Vuetify);
Vue.use(VueClipboard)
Vue.use(VueMindmap)
Vue.use(Vuelidate);
Vue.use(VuePapaParse)
Vue.component(VueCountdown.name, VueCountdown);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken2,
        secondary: colors.grey.darken1,
        accent: colors.grey.black,
        error: colors.grey.accent3,
        info: colors.grey,
        success: colors.grey,
        warning: colors.grey,
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
})
export default vuetify
