<template>
  <v-row class="fill-height align-center" style="background-color:#fff;">
    <v-col cols="12" class="px-0 mx-0 fill-height" SPLASH>
      <v-card color="primary" flat tile dark class="splashImage py-12 my-12 fill-height">
        <v-card-text class="fill-height py-12 my-12">
          <v-row class="text-center align-center fill-height">
            <v-col cols="12" md="3">
              
            </v-col>
            <v-col cols="12" md="6" :class="`${arabic ? 'py-12 my-8' : 'py-12 my-8'}`">
              <v-row class="text-center align-center">
                <v-col cols="12" md="12">
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:36px;font-size:2.0rem;font-weight:bold;" > 
                    {{i18n.its}}
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    {{i18n.its_pub_intro}}
                    
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn outlined x-large rounded @click="signup()" color="white" :class="`${arabic?'arFont':'enFont'} white--text px-8`">
                    {{i18n.start}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0 px-0 mx-0" v-if="false" WHYUS>
      <v-card color="" flat tile class="py-5">
        <v-card-text class="fill-height">
          <v-row class="text-center align-center">
            <v-col cols="12" md="6">
              <img src="/home.jpg" width="70%">
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="text-start align-center px-2">
                <v-col cols="12" md="12">
                  <h2 :class="`${arabic?'arFont':'enFont'} py-3`" style="line-height:1.6;color:#000;text-decoration: underline .7rem solid #000;">
                    خريطة الابتكار
                  </h2>
                </v-col>
                <v-col cols="12" md="12" :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;font-size: 1.1rem;">
                  خريطة مخصصة مبينة بواسطة الذكاء الاصطناعي بناء على مدخلات الافراد والمؤسسات لرسم الطريق نحو المزيد من الأبتكار وحل المشاكل بطرق ابداعية
                </v-col>
                <v-col cols="12" md="12" :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;font-size: 1.1rem;">
                  اكتشف نموذجك العقلي للابتكار الناجح وكن على دراية بطرق التفكير القديمة واللاواعية التي تعرقل الأفكار والسلوكيات المبتكرة الجديدة.
                </v-col>
                <v-col cols="12" md="12">
                  <h2 :class="`${arabic?'arFont':'enFont'} py-3`" style="line-height:1.6;color:#000;text-decoration: underline .7rem solid #000;">
                    لماذا <img src="/FullColorHor.png" width="120px"> ؟
                  </h2>
                </v-col>
                <v-col cols="12" md="12" :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;font-size: 1.1rem;">
                  احدث التقنيات والذكاء الاصطناعي للوصول لنتائج مخصصة بشكل كامل للأفراد والمؤسسات. وينصب تركيزنا على مساعدة الآخرين على اكتشاف طرق جديدة للتفكير وفعل الأشياء. 
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0 px-0 mx-0" v-if="false" DARK>
      <v-card color="primary"  flat tile dark class="slanted">
        <v-card-text>
          <v-row class="text-center align-center py-12 my-12">
            <v-col cols="12" md="3">
              
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="text-center align-center">
                <v-col cols="12" md="12">
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:36px;font-size:2.0rem;font-weight:bold;" > 
                    نظام التفكير المبتكر 
                  </div>
                </v-col>
                <v-col cols="12" md="12" class="text-right">
                  <div :class="`${arabic?'arFont':'enFont'} white--text pt-3`" style="line-height:1.6;font-size:1.2rem;">
                    من أجل تلبية متطلبات البيئة المتغيرة باستمرار ، بما في ذلك معايير المنافسة الجديدة ، يجب أن تكون كل منظمة قادرة على توليد أفكار جديدة وتنفيذ التحسينات بشكل مستمر.
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-1`" style="line-height:1.6;font-size:1.2rem;">
                    يُقيِّم استطلاع دوافع الابتكار نقاط القوة والضعف في فرقك و / أو مؤسستك كما يُرى من خلال عدسة عملية الابتكار ، مع التركيز على المهارات الأساسية المحددة. 
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text pb-6`" style="line-height:1.6;font-size:1.2rem;">
                    سيوفر لك معلومات مهمة لإجراء تغييرات و / أو تحسين أداء الابتكار الخاص بك. 
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0 px-0 mx-0" v-if="false" FEATURE CARDS>
      <v-card color="#fff" flat tile class="py-7 px-8">
        <v-card-text class="fill-height">
          <v-row class="text-center align-center">
            <v-col cols="12">
              <div :class="`${arabic?'arFont':'enFont'}`" style="color:#000;line-height:36px;font-size:2.0rem;font-weight:bold;">من يستفيد من نظام التفكير المبتكر</div>
            </v-col>
            <v-col cols="12">
              <div :class="`${arabic?'arFont':'enFont'}`" style="line-height:1.6;font-size:1.2rem;">
                تسريع اعمالك لتحقيق استدامة أفضل.
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-5" FEATURE>
              <v-row class="text-center align-center">
                <v-col cols="12" md="12">
                  <img src="/images/manager_4.png" width="50%">
                </v-col>
                <v-col cols="12" md="12">
                  <h3 :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;">
                    مدراء تطوير المنتجات
                  </h3>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" class="px-5" FEATURE>
              <v-row class="text-center align-center">
                <v-col cols="12" md="12">
                  <img src="/images/manager_3.png" width="50%">
                </v-col>
                <v-col cols="12" md="12">
                  <h3 :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;">
                    مطورو الاعمال الجديدة
                  </h3>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" class="px-5" FEATURE>
              <v-row class="text-center align-center">
                <v-col cols="12" md="12">
                  <img src="/images/manager_2.png" width="50%">
                </v-col>
                <v-col cols="12" md="12">
                  <h3 :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;">
                    مديري التسويق والموظفين
                  </h3>
                </v-col>
                
              </v-row>
            </v-col>
            <v-col cols="12" md="3" class="px-5" FEATURE>
              <v-row class="text-center align-center">
                <v-col cols="12" md="12">
                  <img src="/images/manager_1.png" width="50%">
                </v-col>
                <v-col cols="12" md="12">
                  <h3 :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#000;">
                    مدراء الموارد البشرية
                  </h3>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div :class="`${arabic?'arFont':'enFont'}`" style="line-height:1.6;font-size:1.2rem;">
                ...وكل من يتطلع إلى تعزيز الإبداع والابتكار
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0 px-0 mx-0" v-if="false" SPLASH>
      <v-card color="primary"  flat tile dark class="secondSplashImage">
        <v-card-text>
          <v-row class="text-center align-center py-12 my-12">
            <v-col cols="12" md="1">
              
            </v-col>
            <v-col cols="12" md="10">
              <v-row class="text-center align-start">
                <v-col cols="12" md="12">
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:36px;font-size:2.0rem;font-weight:bold;" > 
                    اهداف الخريطة الابتكارية 
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-right align-start">
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    تطبيق منهجيات ملموسة لتحقيق الابتكار في مكان العمل.
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    بناء خارطة ابتكارية متكاملة للمؤسسات
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    بناء مجتمع ابتكاري.
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    ممارسة القيادة الإبداعية من خلال تطبيق المهارات في مكان العمل.
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-right">
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    إعادة ترتيب الفرق الابتكارية بشكل افضل.
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    توليد أفكار إبداعية اكثر فاعلية.
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    تمكين المشاركين بمعرفة المفاهيم الشخصية ومفاهيم المؤسسة المسبقة معرفة كيفية كسر قيود التفكير.
                  </div>
                  <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                    <v-icon color="white" size="18">check_circle</v-icon>
                    تطبيق الأساليب والمهارات العملية لفحص الأفكار وصقلها وتنفيذها.
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn outlined x-large rounded @click="signup()" color="white" :class="`${arabic?'arFont':'enFont'} white--text px-8`">
                    {{i18n.start}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="1">
              
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getRandomInt, randomString, getCDate, check, sleep } from '@/customFunction';
import { format, render, cancel, register } from 'timeago.js';
import Footer from '@/components/footer'
export default {
  components: { Footer }, //multiDialog
  data() {
    return {
      loading:false,
      dialog:false,
      dnotification:null,
    }
  },
  
  methods: {
    signup() {
      this.goTo('/login')
    },
    login() {
      this.goTo('/login')
    },
    goTo(path) {
      this.$router.push(path)
    },
  },
  watch:{
  },
  computed:{
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    goalModel(){
      return this.$store.state.goalModel
    },
    keyResultModel(){
      return this.$store.state.keyResultModel
    },
    initModel(){
      return this.$store.state.initiativeModel
    },
    units(){
      return this.$store.state.units
    },
    quarters(){
      return this.$store.state.quarters
    },
    fileTypes(){
      return this.$store.state.fileTypes
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created(){
    try {
      gtag('event', `main_page_visit`);
    } catch (error) {
      // console.log(error)
    }
  },
  
}
</script>

<style>

  .customPattern:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-image: url('/backsection.png');
    background-repeat: repeat;
    height: 100%;
    background-size: 40rem;
    opacity: .15;
  }

  .splashImage {
    background-image: linear-gradient(#EC0B43, rgba(0, 0, 0, 0.7)), url('/images/asklytic.webp');
    background-size:cover;
    background-position:center;
  }

  .secondSplashImage {
    background-image: linear-gradient(#EC0B43, rgba(0, 0, 0, 0.7)), url('/images/asklytic.webp');
    background-size: cover;
    background-position: center center;
    -webkit-filter: blur(5px);
  }

  .slanted {
    padding: 30px;
    box-sizing: border-box;
    clip-path: polygon(0 0,100% 0vw,100% 75%,0 100%)
  }
</style>