<template>
  <div>
    <v-row class="my-5" v-if="!loading">
      <v-col cols="12" md="4">
        <v-card class="statCard">
          <v-card-text>
            <div style="font-size:32px;" class="py-2">
              {{ resource.percentageOfAnsweredQuestions.toFixed(1) }}%
              <v-progress-circular 
                :size="30"
                :width="5"
                color="primary"
                :value="resource.percentageOfAnsweredQuestions">
              </v-progress-circular>
            </div>
            <div style="font-weight:bold;">
              {{i18n['percentage_answered_questions']}}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="statCard">
          <v-card-text>
            <div style="font-size:32px;" class="py-2">
              {{ resource.overallAverageRating.toFixed(1) }} <span style="font-size:14px;">/ 5</span>
              <v-progress-linear
                color="primary"
                height="10"
                :value="resource.overallAverageRating*20"
                striped
                rounded
              ></v-progress-linear>
            </div>
            <div style="font-weight:bold;">
              {{i18n['overall_average_rating']}}
            </div>
            {{i18n.from}} {{resource.totalResponses}} {{i18n.responses}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="statCard">
          <v-card-text>
            <div style="font-size:32px;" class="py-2">
              {{ resource.numberOfUsersWhoAnswered }} {{i18n.users}}
            </div>
            <div style="font-weight:bold;">
              {{i18n['answered_any_question']}}
            </div>
            {{resource.surveyViews}} {{i18n.survey_views}} & {{resource.totalResponses}} {{i18n.responses}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="statCard">
          <v-card-text>
            <h3 class="pb-3">{{i18n.average_rating_per_question}}</h3>
            <apexchart type="bar" height="500" :options="chartOptions" :series="series"></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { format, render, cancel, register } from 'timeago.js';
import axios from 'axios';
const headers = {'Accept': 'application/json'};
const fireapi = 'https://us-central1-asklytic.cloudfunctions.net/main';

export default {
  components: {  },
  props:['uid'],
  data() {
    return {
      loading:false,
      dialog:false,
      pdfreport:null,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      newDialog:false,


      series: [{
        name: "Average",
        type: 'column',
        zIndex: 1,
        data: []

      }, {
        name: "Responses",
        type: 'line',
        zIndex: 2,
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'line',
          height: '6000px',
          sparkline: {
              enabled: false,
          }
        },
        stroke: {
          width: [0, 12]
        },
        
        tooltip: {
          enabled: true,
          shared:true,
          intersect:false,
          
        },
        colors: ['#ec0b43', '#7E36AF'],
        plotOptions: {
          
          // bar: {
          //   borderRadius: 4,
          //   horizontal: false,
          //   columnWidth: '90%',
          //   colors: {
          //     ranges: [{
          //         from: 0,
          //         to: 5,
          //         color: '#ec0b43'
          //     }],
          //   }
          // }
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries:[0, 1],
          style: {
              fontSize: '9px',
              fontWeight: 'bold',
          },
        },
        yaxis: [{
          title: {
            text: 'Average',
            style: {
                fontWeight: 400,
            },
          },
        
        }, {
          opposite: true,
          stepSize:1,
          min:0,
          max:15,
          title: {
            text: 'Responses',
            style: {
                fontWeight: 400,
            },
          }
        }],
        xaxis: {
          categories: [],
          labels: {
            show: false,
          }
        }
      },
    }
  },
  methods: {
    
    getFormat(d){
      return format(d)
    },
    
    clipboardSuccessHandler (){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler () {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    
    async loadResource() {
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.resource = await this.$store.dispatch('getResource', {resource:'/survey-responses-by-survey/' + (this.$route.params.uid ? this.$route.params.uid : this.uid) })

        this.series[0].data.push(...this.resource.questionsWithAverage.map(x=>x.averageRating))
        this.series[1].data.push(...this.resource.questionsWithAverage.map(x=>x.responses))
        this.chartOptions.xaxis.categories.push(...this.resource.questionsWithAverage.map(x=>`${x.id} ${x.label}`))
        
        this.loading = false
        res()
      })
    },
    
  },
  computed:{
    checkDisabled(){
      if(this.user.role == 'master') {
        return false
      } else {
        return this.resource.tokens > 0 ? false : true
      }
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created(){
    await this.loadResource()
  },
}
</script>
<style>
.statCard {
  border-top: 2px solid #ec0b43 !important;
  border-bottom: 4px solid #ec0b43 !important;
  min-height: 136px;
}
</style>