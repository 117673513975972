<template>
  <v-container class="max-width py-0">
    <v-pagination
      v-if="resourceSettings.pagination && resource && resource.last_page"
      v-model="resource.current_page"
      class="my-0"
      :total-visible="7"
      color="primary"
      @input="pageByNumber"
      :length="resource.last_page"
    >
    </v-pagination>
  </v-container>
</template>

<script>
export default {
  props:['resourceSettings', 'resource'],
  methods:{
    loadResource() {
      this.$emit('loadResource')
    },
    pageByNumber(n) {
      this.$emit('pageByNumber', n)
    }
  },
  computed:{
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
  }
}
</script>

<style>

</style>