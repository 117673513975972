import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login';
import notfound from './views/notfound';
import store from './store/index'
import home from './views/home'
import toc from './views/toc'
import preAssessment from './views/preAssessment'
import assessment from './views/assessment'
import results from './views/results'
import finalReport from './views/finalReport'
import pdf from './views/pdf'
import survey from './views/survey'
import result from './views/result'



let menu = store.state.modelMenu
let main = menu.filter(x => !x.subs)
let subs = [].concat(...menu.filter(x => x.subs).map(x => x.subs))
let all = main.concat(subs)
let allMapped = all.map(x=>{
  return {
    path: x.path,
    name: x.text,
    component: () => import(`./views/${x.text}`),
  }
})
// console.log('------------------------------------');
// console.log(all);
// console.log('------------------------------------');


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  beforeRouteUpdate(to, from, next) {
    // react to route changes...
    // don't forget to call next()
    // console.log(to)
    // console.log(from)
    //next()
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/v/:hash',
      name: 'verify',
      component: Login,
    },
    {
      path: '/toc',
      name: 'toc',
      component: toc,
    },
    {
      path: '/register',
      name: 'register',
      component: Login,
    },
    {
      path: '/pre-assessment',
      name: 'preAssessment',
      component: preAssessment,
    },
    {
      path: '/assessment',
      name: 'assessmentPage',
      component: assessment,
    },
    {
      path: '/results',
      name: 'assessmentResults',
      component: results,
    },
    {
      path: '/final-report/:hash?',
      name: 'finalReport',
      component: finalReport,
    },
    {
      path: '/pdf',
      name: 'pdf',
      component: pdf,
    },
    {
      path: '/survey/:uid',
      name: 'survey',
      component: survey,
    },
    {
      path: '/result/:uid',
      name: 'result',
      component: result,
    },
    
    {
      // will match everything
      path: '*',
      component: notfound,
    }
  ].concat(allMapped)
})

router.beforeEach((to, from, next) => {
  let noGate = ['home', 'login', 'register', 'toc', 'verify']
  let user = store.state.user
  // console.log(to.name)
  if(noGate.includes(to.name)) {
    next()
  } else if (user && user.role && !noGate.includes(to.name)) {
    let a = all.filter(x => x.text == to.name)
    .filter(x => x.roles && x.roles.includes(user.role))
    if ((a && a.length) || to.name != 'home') {
      next()
    } else {
      next({ name: 'home'})
    }
  } else if (!user && !noGate.includes(to.name)) {
    next({ name: 'home'})
  } 
  else {
    next()
  }
})


Vue.use(Router);

export default router