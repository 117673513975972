<template>
  <div class="primary">
    <v-row :style="`height:${frameHeight}px;`" class="mt-0 py-5 pb-12 align-center primary splashImage">
      <v-col cols="12" md="4" class="pa-0 elevation-0 align-center">
      </v-col>
      <v-col cols="12" md="4" class="pa-2 py-12 my-12 elevation-0 align-center">
        <v-card class="align-center pa-2 elevation-2 ma-0" >
          <v-card class="text-center primary py-5 elevation-2 align-center">
            <img v-if="site.wlogo" :src="site.wlogo" width="110" alt="">
            <span v-if="!site.logo" class="headline white--text">{{site.name.toUpperCase()}}</span>
          </v-card>
          <v-card-text class="text-center" v-if="verify_screen">
            <v-alert v-if="!loading && verify_success" :class="`${arabic ? 'arFont' : ''}`" dark color="success">
              تم تأكيد ايميلك بنجاح يمكنك الدخول لبدء العمل الآن
            </v-alert>
            <v-alert v-if="!loading && !verify_success" :class="`${arabic ? 'arFont' : ''}`" dark color="error">
              لم ينجح تأكيد ايميلك يرجى المحاولة مجددا او التواصل مع الدعم
            </v-alert>
            <v-btn v-if="!loading && verify_success" color="success" @click="goTo('/login')" :class="`${arabic ? 'arFont' : ''}`">تسجيل الدخول</v-btn>
          </v-card-text>
          <v-card-text class="text-center" v-if="login_screen || register_screen">
            <v-form>
              <v-text-field 
                append-icon="person" 
                name="name" 
                v-if="register_screen"
                :label="i18n.name" 
                type="text" 
                @keypress.enter="handelLogin"
                :class="`${arabic?'arFont':'enFont'}`"
                v-model.trim="user.name">
              </v-text-field>
              <v-text-field 
                append-icon="email" 
                name="email" 
                :label="i18n.email" 
                type="text" 
                @keypress.enter="handelLogin"
                :class="`${arabic?'arFont':'enFont'}`"
                v-model.trim="user.email">
              </v-text-field>
              <v-text-field 
                append-icon="phone" 
                name="phone" 
                v-if="register_screen"
                label="رقم الهاتف" 
                type="text" 
                @keypress.enter="handelLogin"
                :class="`${arabic?'arFont':'enFont'}`"
                v-model.trim="user.phone">
              </v-text-field>
              <v-text-field 
                v-if="!forgetpass"
                append-icon="lock" 
                name="password" 
                :label="i18n.password" 
                id="password" 
                @keypress.enter="handelLogin"
                :class="`${arabic?'arFont':'enFont'}`"
                type="password" 
                v-model="user.password">
              </v-text-field>
              <v-checkbox 
                label="اوافق على الشروط والاحكام بعد الاطلاع عليها" 
                :class="`${arabic?'arFont':'enFont'}`" 
                v-if="register_screen"
                v-model="terms" 
                :value="true">
              </v-checkbox>
              <v-btn color="primary" v-if="register_screen" text small :class="`${arabic?'arFont':'enFont'}`" @click="goTo('/toc')">الشروط والاحكام</v-btn>
            </v-form>
            <div><span v-if="error" :class="`${ arabic ? 'arFont' : '' } error--text`">{{ error }}</span></div>
          </v-card-text>
          <v-card-actions v-if="login_screen">
            <span v-if="!forgetpass" 
              @click="forgetpass  = true" 
              style="cursor:pointer;font-size:12px;"
              :class="`${arabic ? 'arFont' : ''} mx-2 blue--text`">{{i18n.forgetPassword}}</span>
            <span v-if="forgetpass" 
              @click="forgetpass  = false" 
              style="cursor:pointer;font-size:12px;" 
              :class="`${arabic ? 'arFont' : ''} mx-2 blue--text`">{{i18n.login}}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="!forgetpass && false" @click="()=>{login_screen=false;register_screen=true;forgetpass=false}" text large :loading="loading" 
              :class="`${arabic?'arFont':'enFont'}`">
              {{i18n.register}}
            </v-btn>
            <v-btn color="primary" v-if="!forgetpass" @click="handelLogin" large :loading="loading" 
              :class="`${arabic?'arFont':'enFont'}`">
              {{i18n.login}}
            </v-btn>
            <v-btn v-if="forgetpass" color="primary" @click="resetPass" :loading="loading" 
              :class="`${arabic ? 'arFont' : ''}`">
              {{i18n.sendResetEmail}}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="register_screen">
            <v-spacer></v-spacer>
            
            <v-btn color="primary" v-if="!forgetpass" text large :loading="loading" 
              @click="()=>{login_screen=true;register_screen=false}"
              :class="`${arabic?'arFont':'enFont'}`">
              {{i18n.login}}
            </v-btn>
            <v-btn color="primary" :disabled="!terms" v-if="!forgetpass" @click="register" large :loading="loading" 
              :class="`${arabic?'arFont':'enFont'}`">
              {{i18n.register}}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="!forgetpass && false">
            <v-btn color="error" block x-large @click="OauthLogin(0)" large :loading="loading" :class="`${arabic?'arFont':'enFont'}`">{{ i18n.loginWithGoogle }}</v-btn>
          </v-card-actions>
          <v-card-actions v-if="!forgetpass && false">
            <v-btn block text @click="switchLanguage('en')" v-if="language == 'ar'" class="mb-1">
            english
            </v-btn>
            <v-btn block text v-if="language == 'en'" @click="switchLanguage('ar')" :class="`mb-1 arFont`">
              عربي
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="pa-0 elevation-0 align-center">
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import axios from 'axios';
const headers = {'Accept': 'application/json'};
import { getRandomInt, randomString, getCDate} from '@/customFunction';
const fireapi = 'https://us-central1-asklytic.cloudfunctions.net/main';
import Footer from '@/components/footer'

export default {
  components: { Footer },
  data: () => ({
    frameHeight:0,
    loading: false,
    login_screen:true,
    register_screen:false,
    verify_screen:false,
    verification:false,
    forgetpass:false,
    error:null,
    resetStat:null,
    errorTimeOUt:null,
    user: {
      email: null,
      password: null
    },
    session_token:null,
    sms_code:null,
    REMOVE:null,
    installed:true,
    magic:false,
    cap:false,
    captchaCheck:null,
    captcha:null,
    splash:{url:'/joobbie-3.png', cover:true, contain:false},
    splashes:[
      {url:'/joobbie-3.png', cover:false, contain:true}, 
      {url:'/joobbie-2.png', cover:false, contain:true},
      {url:'/frankfurt.jpg', cover:true, contain:false}
    ],
    terms:false,
    verify_success:false,
  }),

  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    async resetPass(){
      this.loading = true
      // console.log(this.user)
      let r = await axios.post(`${this.api}/reset`, this.user, {headers}).then(x=>x.data)
      if(r.success) {
        this.$emit('snackbarNotification', `تم ارسال الايميل`, 'success')
        this.forgetpass = false
      }
      // console.log(r)
      this.loading = false
    },
    setCookie(name, value, days) {
      var expires = "";
      var maxAge = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        maxAge = "; max-age=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    OauthLogin (p) {
      this.loading = true
      var provider
      switch (p) {
        case 0:
          provider = new firebase.auth.GoogleAuthProvider();
          break;
        default:
          break;
      }
      firebase.auth().signInWithPopup(provider).then( async (u) => {
        console.log(u)
        let a = u.additionalUserInfo
        let n = a.profile
        let nu = {
          'provider': a.providerId,
          'name': n.name,
          'email': n.email,
          'scopes': n.granted_scopes,
          'image': n.picture,
          'verified_email': n.verified_email ? 1 : 0,
          'uid': u.user.uid
        }
        if(a.isNewUser) {
          let r = await axios.post(`${this.api}/user`, nu, headers).then(x=>x.data)
          // console.log(r);
          if(!r.error) {
            this.loading = false
            this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'error')
          }
        } else {
          let r = await axios.post(`${this.api}/user/login`, nu, headers).then(x=>x.data)
          // console.log(r)
          if(!r.error) {
            this.loading = false
            this.$store.state.centralLogin = true
            this.$store.state.access_token = r.access_token
            this.setCookie('_ASK_LYTIC', r.access_token, 1)
            this.$store.state.user = r.user
            if(r.user && r.user.role == 'admin')  {
              this.goTo('/dashboard')
            } else if(r.user && r.user.role == 'superadmin')  {
              this.goTo('/dashboard')
            } else {
              this.goTo('/dashboard')
            }
          } else if (r.error == 'unuthorized_access') {
            this.loading = false
            this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'error')
            this.error = this.i18n.accountCreated
            this.resetError()
          } else {
            this.loading = false
            this.$emit('snackbarNotification', `${r.error}`, 'error')
            this.error = r.error
            this.resetError()
          }
        }
        this.Auth(u.user.ma)
      })
      .catch((error) => {
        this.error = error.message
        this.loading = false
        this.$emit('snackbarNotification', `Error -> Contact Admin`, 'error')
        this.resetError()
      });
    },
    async handelLogin(){
      let e = this.user.email
      let p = this.user.password
      let r = await axios.post(`${this.api}/login`, {email:e, password:p}, {headers}).then(x=>x.data)
      if(!r.error) {
        this.loading = false
        this.$store.state.centralLogin = true
        this.$store.state.access_token = r.access_token
        this.setCookie('_ASK_LYTIC', r.access_token, 1)
        this.$store.state.user = r.user
        if(r.user && r.user.role == 'admin')  {
          this.goTo('/dashboard')
        } else if(r.user && r.user.role == 'superadmin')  {
          this.goTo('/dashboard')
        } else {
          this.goTo('/dashboard')
        }
        try {
          gtag('event', `login_success`);
          gtag('event', `login_success_${r.user.uuid}`);
        } catch (error) {
          // console.log(error)
        }
      } else if (r.error == 'unuthorized_access') {
        this.loading = false
        this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'error')
        this.error = this.i18n.accountCreated
        this.resetError()
      } else {
        this.loading = false
        this.$emit('snackbarNotification', `${r.error}`, 'error')
        this.error = r.error
        this.resetError()
      }
    },
    async login() {
      this.loading = true;
      
      if(!e){
        this.loading = false;
        this.error = `${this.i18n[email_required]}`
        this.resetError()
        return true
      }
      if(!p){
        this.loading = false;
        this.error = `${this.i18n[password_required]}`
        this.resetError()
        return true
      }
      firebase.auth().signInWithEmailAndPassword(e, p).then(
        (resp) => {
          // console.log('resp', resp)
          this.Auth(resp.user.ma)
        },
        async (err) => {
          // console.log(err)
          this.loading = false;
          try {
            gtag('event', `login_error`);
            gtag('event', `login_error_${err.code}`);
          } catch (error) {
            // console.log(error)
          }
          console.log(err)
          console.log(this.i18n[err.message])
          this.error = `${this.i18n[err.message]}`
          if (err.code == 'auth/wrong-password') {
            this.error = `${this.i18n["INVALID_PASSWORD"]}`
          } else if(err.code == 'auth/user-not-found') {
            // User Not Found
            this.error = `${this.i18n["user_not_found"]}`
          }
          this.resetError()
        }
      );
    },
    async Auth(token){
      let r = await axios.post(`${fireapi}/loginUser`, {token}, {headers}).then(x=>x.data)
      console.log(r)
      if(!r.error) {
        this.loading = false
        this.$store.state.centralLogin = true
        this.$store.state.access_token = r.access_token
        this.setCookie('_ASK_LYTIC', r.access_token, 1)
        this.$store.state.user = r.user
        if(r.user && r.user.role == 'admin')  {
          this.goTo('/dashboard')
        } else if(r.user && r.user.role == 'superadmin')  {
          this.goTo('/dashboard')
        } else {
          this.goTo('/dashboard')
        }
        try {
          gtag('event', `login_success`);
          gtag('event', `login_success_${r.user.uuid}`);
        } catch (error) {
          // console.log(error)
        }
      } else if (r.error == 'unuthorized_access') {
        this.loading = false
        this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'error')
        this.error = this.i18n.accountCreated
        this.resetError()
      } else {
        this.loading = false
        this.$emit('snackbarNotification', `${r.error}`, 'error')
        this.error = r.error
        this.resetError()
      }
    },
    async getCurrentUser(){
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        // Send token to your backend via HTTPS
        // console.log(idToken)
        
      }).catch(function(error) {
        // Handle error
      });
      this.loading = false;
    },
    async register () {
      if(!this.user.name) {
        this.error = `${this.i18n[name_required]}`
        this.resetError()
        return
      }
      if(!this.user.email) {
        this.error = `${this.i18n[email_required]}`
        this.resetError()
        return
      }
      if(!this.user.phone) {
        this.error = `رقم الهاتف مطلوب`
        this.resetError()
        return
      }
      if(!this.user.password) {
        this.error = `${this.i18n[password_required]}`
        this.resetError()
        return
      }
      this.loading = true;
      let user = this.user
      try {
        let a = await axios.post(`${fireapi}/registerNewUser`, user, {headers:headers}).then(x=>x.data)
        // console.log(a)
        if(!a.error) {
          this.loading = false;
          this.error = null
          let nu = {
            'provider': a.user.providerData[0].providerId,
            'name': a.user.displayName,
            'email': a.user.email,
            'phone': user.phone,
            'image': a.user.photoURL,
            'verified_email': a.user.emailVerified,
            'uid': a.user.uid 
          }
          let r = await axios.post(`${this.api}/user`, nu, headers).then(x=>x.data)
          // console.log(r);
          if(!r.error) {
            this.loading = false
            this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'error')
          }
          this.$store.state.verification = a.emailVerified
          this.login()
        } else {
          this.error = `${this.i18n[a.error.message] ? this.i18n[a.error.message] : a.error.message}` 
          if(this.error == `user_exists_login`) {
            this.login_screen = true
            this.register_screen = false
            this.forgetpass = false
          }
          this.resetError()
          this.loading = false;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    resetError(){
      clearTimeout(this.errorTimeOUt)
      this.errorTimeOUt = setTimeout(x=>{ this.error = null }, 4000)
    },
    enLanguage(){
      this.$emit('changeLang', 'en')
    },
    arLanguage(){
      this.$emit('changeLang', 'ar')
    },
    switchLanguage(l) {
      this.$store.state.language = l
      if(l=='ar') {
        this.$vuetify.rtl = true
      } else {
        this.$vuetify.rtl = false
      }
      this.getSplash()
    },
    setH(){
      let h = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
      this.frameHeight = h + 50
    },
    getSplash(){
      let a = getRandomInt(0, this.splashes.length-1)
      // console.log(a)
      this.splash = this.splashes[a]
    },
    async verifyEmail(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        let a = await this.$store.dispatch('getResource', {resource:`/verify/${this.$route.params.hash}`})
        // console.log(a)
        this.verify_success = a.success
        this.loading = false
      })
    },
    async getAction(){
      if(this.$route.name == 'login') {
        this.login_screen = true
        this.register_screen = false
        this.verify_screen = false
      } else if (this.$route.name == 'register') {
        this.login_screen = false
        this.register_screen = true
        this.verify_screen = false
      } else if (this.$route.name == 'verify'){
        this.login_screen = false
        this.register_screen = false
        this.verify_screen = true
        await this.verifyEmail()
      }
    }
  },
  props: {
    
  },
  watch:{
    api(){},
    $route (to, from){
      this.getAction()
    },
  },
  computed:{
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    api(){
      return this.$store.state.settings.apiBase
    }
  },
  async created(){
    this.getSplash()
    this.getAction()
    let r = await axios.get(`${this.api}/live`).then(x=>x.data)
  },
  mounted(){
    this.setH()
  }

};
</script>
<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
