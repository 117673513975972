<script>
import { Radar } from 'vue-chartjs'

export default {
  extends: Radar,
  props:{
    data:{
      default: []
    },
    small:false
  },
  computed:{
    fontCustomSize(){
      let win = window.innerWidth;
      let factor = this.language == 'ar' ? 20 : 15
      let size  = factor / 1286 * win
      return size
    },
    language(){
      return this.$store.state.language
    },
  },
  mounted () {
    console.log(this.fontCustomSize)

    this.renderChart(this.$props.data, {
        legend: {
            display: false,
            labels: {
                fontColor: "#000080",
                fontFamily: "'Tajawal', sans-serif",
            }
        },
        scale: {
            angleLines: {
                display: false
            },
            pointLabels: {
              fontColor: "#023d66",
              fontFamily: "'Tajawal', sans-serif",
              fontSize:this.fontCustomSize,
            },
            ticks: {
                fontFamily: "'Tajawal', sans-serif",
                suggestedMin: 0,
                suggestedMax: 100,
                minor:{
                }
            },
            gridLines: {
              color:'#023d66'
            },
        },
        font:{
          family: "'Tajawal', sans-serif",
          size:18,
        }
    })
    console.log(this.fontCustomSize)

  }
}
</script>

<style>

</style>
