<template>
  <v-row class="d-print-none">
    <v-col cols="12" class="py-0 px-0 mx-0" SPLASH>
      <v-card color="primary darken-1" flat tile dark>
        <v-card-text>
          <v-row class="">
            <v-col cols="12" md="7">
              <v-row class="align-top">
                <v-col cols="12" v-if="false">
                  <img :src="site.wlogo" alt="baya3" width="150px">
                  <span 
                    v-if="site && site.name && !site.logo" 
                    :class="`${language == 'ar' ? 'arFont' : null} ${isLogin? '' : 'white--text'}`"
                    @click="goTo('/')"
                    style="cursor:pointer;"
                  >{{ site.name.toUpperCase() }}</span>
                </v-col>
                <v-col cols="12" class="px-8" v-if="false">
                  <span :class="`${arabic?'arFont':'enFont'}`">
                  {{i18n.footer_intro}}
                    
                  </span>
                </v-col>
                <v-col cols="12" class="px-4" :class="`${arabic?'arFont':'enFont'} white--text`">
                  {{i18n.copyright_reserved}} <img :src="site.wlogo" width="60px"> © {{new Date().getFullYear()}}
                </v-col>
                <v-col cols="12" class="px-4" :class="`${arabic?'arFont':'enFont'} white--text`" v-if="false">
                  <v-btn color="white" @click="goTo('/toc')" text large>{{i18n.toc}}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col cols="12" md="5" v-if="false">
              <v-row class="align-top">
                <v-col cols="12" class="px-4" v-if="false" :class="`${arabic?'arFont':'enFont'} subtitle`">
                  تابعنا على مواقع التواصل الإجتماعي
                </v-col>
                <v-col cols="12" class="px-4" v-if="false" :class="`${arabic?'arFont':'enFont'} subtitle`">
                  <v-icon>facebook</v-icon>
                </v-col>
                <v-col cols="12" class="px-4" :class="`${arabic?'arFont':'enFont'} subtitle`">
                  {{i18n.contact_us_through_email}}
                </v-col>
                <v-col cols="12" class="px-4" :class="`${arabic?'arFont':'enFont'} subtitle`">
                  info@evotik.com
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';
const headers = {'Accept': 'application/json'};

import { format, render, cancel, register } from 'timeago.js';
export default {
  data() {
    return {
      loading:false,
    }
  },
  
  methods: {
    goTo(path) {
      this.$router.push(path).catch(err => {})
    },
  },
  computed:{
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    goalModel(){
      return this.$store.state.goalModel
    },
    keyResultModel(){
      return this.$store.state.keyResultModel
    },
    initModel(){
      return this.$store.state.initiativeModel
    },
    units(){
      return this.$store.state.units
    },
    quarters(){
      return this.$store.state.quarters
    },
    fileTypes(){
      return this.$store.state.fileTypes
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created() {
    
  }
}
</script>
