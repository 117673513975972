import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { getRandomInt, randomString, getCDate, check, sleep, genKey } from '@/customFunction';
const headers = {'Accept': 'application/json'};
import router from '../router'
import {
  firebase,
  auth
} from '@/fb'

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    site: {
      "avatar": "https://firebasestorage.googleapis.com/v0/b/cip-generic.appspot.com/o/default-user.png?alt=media&token=82389d15-0419-4d2c-8b5e-5fe3e18c3ca7",
      "language": "ar",
      "logo": "/FullColorHor.png",
      "wlogo": "/weblogo.png",
      "name": "Asklytic",
      "theme": {
        "light": {
          "accent": "#023d66",
          "error": "#FF5252",
          "info": "#2196F3",
          "primary": "#EC0B43",
          "secondary": "#0A2463",
          "success": "#4CAF50",
          "warning": "#FFC107"
        }
      }
    },
    i18n: {
      en: {
        results:`Survey Results`,
        UserResults:`Users Results`,
        QuestionsResults:`Questions Results`,
        started:`Started at`,
        ended:`Ended at`,
        average_rating_per_question:`Average rating per Question`,
        overall_average_rating:`Overall average rating`,
        answered_any_question:`Answered any question`,
        survey_views:`Survey Views`,
        stats:`Statistics`,
        percentage_answered_questions:`Percentage of answered questions`,
        analysis_by_question:`Responses Per Questions`,
        user_responses:`User Responses`,
        responses:`Responses`,
        started_at:`Started at`,
        location:`Location`,
        already_done:`You have already submitted answers to this survey`,
        next_question:`Next Question`,
        dashboard_title:`Choose a Survey`,
        label:`Label`,
        question_type:`Question Type`,
        add:`Add`,
        edit:`Edit`,
        questions:`Questions`,
        all_surveys:`All Surverys`,
        add_survey:`Add a Survey`,
        surveys:`Surveys`,
        /////
        material_agree_to_instructions:`I have read instructions and ready to view material`,
        view_material:`View Material`,
        material_instructions_title: `Instructions on using material`,
        material_instructions_subtitle: `Please check the following instructions thoroughly`,
        certification:`Training`,
        preMaterial:'Material',
        material:'Material',
        survey_exam:'Survey',
        exam:'Exam',
        certificates_sub:`Download Approved Certificated`,
        print_certificate_pdf:`Print Certificate`,
        certificates:`Certificates`,
        no_user_finished:`Non of the users has finsihed the survey`,
        comp_final_report:`Comprehensive Final Report`,
        final_report:`Final Report`,
        /////////////////// BAYAA
        dashboard:`Dashboard`,
        survey:`Survey`,
        result:`Result`,
        close_menu:`close menu`,
        its:`Asklytic`,
        its_intro:`Get Answers You Can Depend On`,
        no_notifications:`No Notifications`,
        speed_up_innovation: `You can accelerate innovation in:`,
        products_tech_services: `New Products, Technologies, Services Development`,
        strategies_values: `Future Business Models, Strategies, Plans, New Values Development`,
        create_lead_stackholders: `Ideas Generation, Projects Leading, Stake Holder Engagement`,
        marketing_efficiency: `New Marketing Strategies, New Development Methodology, Process Improvement Creation`,
        new_thinking_methods: `Discover new thinking methods`,
        footer_intro: `The latest technologies and artificial intelligence to achieve fully customized results for individuals and organizations to help them discover new ways of thinking and doing things.`,
        copyright_reserved: `All rights reserved for`,
        contact_us_through_email: `Contact us through Email:`,
        toc:`Terms & Conditions`,
        instructions_title: `Survey Instructions`,
        instructions_subtitle: `Please check the following instructions thoroughly`,
        time_is_open: `There is no time limit to this survey`,
        survey_instruction_intro: `Questions are answered by choosing one of the following answers:`,
        to_almost_no_extent: `To almost no extent`,
        to_a_slight_extent: `To a slight extent`,
        to_a_moderate_extent: `To a moderate extent`,
        to_great_extent: `To a great extent`,
        to_very_great_extent: `To a very great extent`,
        survey_done_check_result: `Survey is done you can check the result`,
        agree_to_instructions: `I have read the instructions and ready to start survey`,
        start_survey: `Start Survey`,
        survey_result: `Survey Result`,
        all_users: `All users`,
        manage_platform_users: `Manage platform users`,
        institutions: `Institutions`,
        users_count: `Users number`,
        users_started: `Started survey`,
        users_finished: `Finished survey`,
        user_users: `user(s)`,
        left_tokens: `left`,
        add_user: `add user`,
        institution:`institution`,
        empoyee_name: `Empoyee name`,
        started_survey: `Started survey`,
        finished_survey: `Finished survey`,
        print_pdf: `Print report`,
        update: `Update`,
        survey_progress: `Survey progress`,
        building_next_question: `Building next question`,
        next: `Next`,
        end_and_show_results: `Finish & View Result`,
        creativity_problem: 'Creativity & Problem Solving',
        risk_taking: 'Risk Taking',
        com_relation: 'Communication & Relationships',
        excution_managment: 'Execution & Management',
        INVALID_PASSWORD: 'Invalid Password',
        user_not_found: `User Not Found`,
        survey_result: `Survey result`,
        survey_result_map: `Survey result and innovation map`,
        final_result: `Final result`,
        point: `Point`,
        detailed_report: `Detailed Report Ready For Print`,
        stop_finish_later:`You can stop in the middle of the survey and come back later to continue where you left`,
        use_pc_or_phone: `You can finish this survey using your PC or Smart Phone`,
        its_pub_intro:`Get Answers You Can Depend On`,


        catalog:`Catalog`,
        shipping:`Shipping`,
        tickets:`Tickets`,
        wallet:`Wallet`,
        account:`Account`,
        store:`Store`,
        accounts:`Accounts`,
        requests:`Requests`,
        leadsMaster:`All Leads`,
        ///////
        item_name: 'ITEM NAME',
        price: 'PRICE',
        quantity: 'Quantity',
        order_total: 'Order Total',
        your_details: 'Your Details',
        item_total: 'ITEM TOTAL',
        continue_shopping: 'continue shopping',
        submit_order: 'submit order',
        cart: 'Cart',
        order_now: 'order now',
        finish_order: 'finish order',
        time_left: 'Time Left',
        details: 'Details',
        country_choose: 'Please choose your country',
        items_in_order: 'Items in order',
        name: 'Name',
        phone_number: 'Phone Number',
        add_to_cart: 'Add to cart',
        thank_you: 'Your order was submited',
        tomorrow: `Come back tomorrow for our latest offers`,
        deliveryJO: `3 JDs added for Delivery Charges`,
        deliveryJOS: `You earned FREE Delivery for your order`,
        deliverySA: `50 Riyals added for Delivery Charges`,
        deliverySAS: `You earned FREE Delivery for your order`,
        delivery_total:`Delivery Fees`,
        delivery_free: `You earned FREE Delivery for your order`,
        sold: `sold `,
        piece: ` pieces`,
        added_to_cart: `added to cart`,
        size_color:`size/color`,
        cancel:`cancel`,
        total: 'Total',
        lowestJO:`Lowest Prices In Jordan`,
        lowestSA:`Lowest Prices In KSA`,
        dismiss:`Dismiss`,
        search:`Search`,
        back:`Go back`,
        tax:`Tax Included`,
        //// nav
        start:`Start now`,
        emailSoftware:`Emails System`,
        campaigns:`Campaigns`,
        campaign:`Campaign`,
        lists:`Lists`,
        list:`List`,
        templates:`Templates`,
        template:`Template`,
        accountCreated:`Account Created`,
        sent:`Sent`,
        opened:`Opened`,
        clicked:`Clicked`,
        lastChange:`Last Change`,
        agent:`Employee`,
        access:`Access`,
        userRole: `User Role`,
        user: `User`,
        users: `Users`,
        admin: `Admin`,
        superadmin: `Super Admin`,
        userEmail: `User Email`,
        userName: `Username`,
        close: `Close`,
        position: `Position`,
        submitBtn: `Save`,
        reload: `reload`,
        subject: `Subject`,
        fromName: `From Name`,
        fromEmail: `From Email`,
        replyTo: `Reply To`,
        lastUpdated: `Last Updated`,
        sendingActive: `Sending...`,
        inDraft: `Draft`,
        alreadySent: `Sent`,
        sendIconTooltip: `Send Now`,
        scheduleIconTooltip: `Send Later`,
        valid_email: `Please enter a valid Email`,
        leads: `Leads`,
        subscribers: `Subscribers`,
        suppression: `Supression`,
        offices: `Offices`,
        loginWithGoogle: `Login with Google`,
        register: `Register`,
        forgetPassword: `Forget password?`,
        sendResetEmail:`Send Reset Email`,
        please_use_google_to_login: `Please use Google to login`,
        user_not_found: `Account does not exist`,
        listings: `Listings`,
        applicationsCenter: `Applications`,
        cv: `Resume`,
        cvs: `Resumes`,
        resume: `Resume`,
        resumes: `Resumes`,
        coverletters: `Cover Letters`,
        appsettings: `Settings`,
        applications: `Applications`,
        inbox: `Inbox`,
        corporate: `Corporate`,
        billing: `Billing`,
        receipts: `Receipts`,
        plan: `Plan`,
        upgradePlan: `Upgrade`,
        addToShortList: `Add To Shortlist`,
        shortlists: `Shortlists`,
        note: `Note`,
        from: `From`,
        to: `To`,
        latest: `Latest`,
        specialty: `Specialty`,
        city: `City`,
        state: `State`,
        verified: `Verified Email`,
        prefer: `Prefer Email`,
        limit: `Limit`,
        description: `Description`,
        "cover-letters": `Cover Letters`,
        language: `Language`,
        header: `Letter Header`,
        greeting: `Letter Greeting`,
        introduction: `Letter Introduction`,
        body: `Letter Body`,
        closing: `Letter Closing`,
        signature: `Letter Signature`,





          /////////////
            captcha:`Please enter the code in the picture above`,
            wrong_captcha: `You have entered a wrong code`,
            only_numbers:`Only numbers allowed`,
            pdfReportsIconTooltip: `Generate Report`,
            generateReport:`Generate Report`,
            cant_be_imported:`User cant be imported`,
            domain_not_allowed: `Domain is not allowed`,
            email: `Email`,
            password: `Password`,
            home: `Home`,
            login: `Login`,
            send: `send`,
            max_retries: `Max retries reached`,
            wrong_code: `Wrong code`,
            expired: `Expired`,
            user_already_exists: `User already exists`,
            success: `Success`,
            not_found: `Item Not found`,
            all_fields_required: `All fields required`,
            item_name_missing: `An item name is missing`,
            userSettings:`User Settings`,
            mySpace: `My Data`,
            notifications: `Notifications`,
            progress: `Progress`,
            reports: `Reports`,
            report: `Report`,
            managment: `Management`,
            employees: `Import Employees`,
            employeesSub: `Employee Management`,
            settings: `Settings`,
            logout: `Log out`,
            search:`Search`,
            totalProgress:`Total Progress`,
            status: `Status`,
            change: `Edit`,
            editIconTooltip: `Edit`,
            deleteIconTooltip: `Delete`,
            cancelBtn: `cancel`,
            addBtn: `add`,
            updateBtn: `Update`,
            add: `Add`,
            update: `Update`,
            delete: `Delete`,
            deleteMessage: `Are you sure you want to remove this item and all related items?`,
            required: `Required`,
            upload: `Upload`,
            confidential: `Confidential`,
            normal: `Normal`,
            date: `Date`,
            new: `New`,
            usersSettings: `User Role Settings`,
            updateUser: `Update User`,
            platformSettings: `Platform Settings`,
            thresholdSettings: `Threshold Settings`,
            name: `Name`,
            percentageWord: `Percentage`,
            saveSetting: `Save Settings`,
            slug: `Slug (no space)`,
            arabic: `Arabic`,
            english: `English`,
            phoneNumber: `Phone`,
            importUser: `Import User & Open an account`,
            employeeData: `Employee Data`,
            employee: `Employee`,
            accept: `Accept`,
            type: `Type`,
            reject: `Reject`,
            rejectMessage: `Rejection Note`,
            pending:`Pending`,
            approved: `Approved`,
            dones: `Done`,
            todos: `To Dos`,
            supports: `Support`,
            comments: `Comments`,
            reportName: `Report Name`,
            fromDate: `From Date`,
            toDate: `To Date`,
            by: `by`,
            submitReport: `Submit Report`,
            deleteReport: `Delete Report`,
            updateReport: `Update Report`,
            myReports: `My Reports`,
            all: `All`,
            totals: `Totals`,
            lastSeen: `Last Update`,
            user_does_not_exist: `User does not exist`,
            viewIconTooltip: `View details`,
            reportsIconTooltip: `View Reports`,
            closeBtn: `Close`,
            SMSCode: `SMS Code`,
          ////////////////
      },
      ar: {
        surveys:`الدراسات`,
        material_agree_to_instructions:`لقد اطلعت على التعليمات الخاصة بالمادة العلمية`,
        view_material:`عرض المادة العلمية`,
        material_instructions_title: `التعلميات الخاصة باستخدام المادة العلمية`,
        material_instructions_subtitle: `يرجى الاطلاع بشكل دقيق على التعليمات التالية`,
        certification:`التدريب`,
        preMaterial:'المادة العلمية',
        material:'المادة العلمية',
        survey_exam:'التقييم',
        exam:'الاختبار',
        certificates_sub:`طباعة الشهادات`,
        print_certificate_pdf:`طباعة الشهادة`,
        certificates:`الشهادات المعتمدة`,
        no_user_finished:`لم يكمل اي مستخدم التقييم`,
        comp_final_report:`تقرير نهائي شامل`,
        final_report:`التقرير النهائي`,
        /////////////////// BAYAA
        dashboard:`الرئيسية`,
        survey:`التقييم`,
        result:`النتيجة`,
        close_menu:`اغلاق القائمة`,
        its:`Asklytic`,
        its_intro:`اجابات يمكنك الاعتماد عليها`,
        no_notifications:`لا يوجد اشعارات`,
        speed_up_innovation: `يمكنك تسريع الابتكار في:`,
        products_tech_services: `المنتجات الجديدة، التقنيات، تطوير الخدمات`,
        strategies_values: `نماذج الأعمال المستقبلية، الاستراتيجيات، الخطط، تطوير القيم الجديدة`,
        create_lead_stackholders: `توليد الأفكار، قيادة المشاريع، إشراك أصحاب المصلحة`,
        marketing_efficiency: `استراتيجيات التسويق الجديدة، الكفاءة التشغيلية في المنظمات`,
        new_thinking_methods: `اكتشف طرق جديدة للتفكير`,
        footer_intro: `احدث التقنيات والذكاء الاصطناعي للوصول لنتائج مخصصة بشكل كامل للأفراد والمؤسسات لمساعدتهم في اكتشاف طرق جديدة للتفكير وفعل الأشياء.`,
        copyright_reserved: `جميع الحقوق محفوظة لشركة`,
        contact_us_through_email: `تواصل معنا من خلال البريد الإلكتروني:`,
        toc:`الشروط والأحكام`,
        instructions_title: `التعليمات الخاصة بالتقييم`,
        instructions_subtitle: `يرجى الاطلاع بشكل دقيق على التعليمات التالية`,
        time_is_open: `الوقت لهذا التقييم مفتوح`,
        survey_instruction_intro: `يتم الاجابة على الاسئلة في هذا التقييم عبر اختيار احد الخيارات التالية:`,
        to_almost_no_extent: `لا تقريبا`,
        to_a_slight_extent: `إلى حد ما`,
        to_a_moderate_extent: `إلى حد معتدل`,
        to_great_extent: `إلى حد كبير`,
        to_very_great_extent: `إلى حد كبير جدا`,
        survey_done_check_result: `تم الانتهاء من التقييم يمكنك الاطلاع على النتيجة`,
        agree_to_instructions: `لقد اطلعت على التعليمات وانا جاهز لخوض التقييم`,
        start_survey: `ابدأ التقييم الان`,
        survey_result: `نتيجة التقييم`,
        all_users: `جميع المستخدمين`,
        manage_platform_users: `ادارة مستخدمين المنصة`,
        institutions: `المؤسسات`,
        users_count: `عدد المستخدمين`,
        users_started: `عدد من بدأ التقييم`,
        users_finished: `عدد من انهى التقييم`,
        user_users: `مستخدم/مستخدمين`,
        left_tokens: `يتبقى لديك`,
        add_user: `اضافة مستخدم`,
        institution:`المؤسسة`,
        empoyee_name: `اسم الموظف`,
        started_survey: `بداية التقييم`,
        finished_survey: `الانتهاء من التقييم`,
        print_pdf: `طباعة التقرير`,
        update: `تحديث`,
        survey_progress: `التقدم في التقييم`,
        building_next_question: `تكوين السؤال التالي`,
        next: `التالي`,
        end_and_show_results: `انهاء التقييم وعرض النتائج`,
        creativity_problem: 'الابداع وحل المشاكل',
        risk_taking: 'المخاطرة',
        com_relation: 'التواصل والعلاقات',
        excution_managment: 'التطبيق والادارة',
        INVALID_PASSWORD: 'كلمة السر غير صحيحة',
        user_not_found: `المستخدم غير معرف`,
        survey_result: `نتيجة التقييم`,
        survey_result_map: `نتيجة التقيم وخريطة الابتكار الخاصة`,
        final_result: `النتيجة النهائية`,
        point: `نقطة`,
        detailed_report: `تقرير تفصيلي جاهز للطباعة`,
        stop_finish_later:`يمكن التوقف عند سؤال معين، والعودة لاكمال التقييم من حيث توقفت`,
        use_pc_or_phone: `يمكن اجراء التقييم من الكمبيوتر او من الهاتف`,
        its_pub_intro:`اجابات يمكنك الاعتماد عليها`,
        //////////////////
        catalog:`منتجاتك`,
        shipping:`الشحن`,
        tickets:`الدعم`,
        wallet:`المحفظة`,
        account:`حسابي`,
        store:`متجرك`,
        accounts:`الحسابات`,
        requests:`طلبات السحب`,
        leadsMaster:`كل الطلبات`,
        //////
        item_name: 'اسم المنتج',
        price: 'السعر',
        quantity: 'الكمية',
        order_total: 'المجموع النهائي',
        your_details: 'التفاصيل',
        item_total: 'المجموع',
        continue_shopping: 'مواصلة التسوق',
        submit_order: 'إنهاء عملية الشراء',
        cart: 'سلة المشتريات',
        order_now: 'أطلبه الان',
        finish_order: 'إنهاء الشراء',
        time_left: 'الوقت المتبقي',
        details: 'مواصفات المنتج',
        country_choose: 'الرجاء اختيار دولتك',
        items_in_order: 'المشتريات',
        name: 'الاسم',
        phone_number: 'رقم الهاتف',
        add_to_cart: 'اضف الى السلة',
        thank_you: `تم ارسال طلبك بنجاح`,
        tomorrow: `عد غدا مجددا لزيارتنا لاخر عروضنا`,
        deliveryJO: `3 دنانير أجور توصيل`,
        deliverySA: `اضف منتج اخر لتحصل على فينيير مجانا`,
        deliveryJOS: `لقد حصلت على توصيل مجاني لطلبك`,
        deliverySAS: `لقد حصلت على فينيير مجاني`,
        delivery_total:`اجور التوصيل`,
        delivery_free: `لقد حصلت على توصيل مجاني لطلبك`,
        sold: `مباع `,
        piece: ` قطعة`,
        added_to_cart: `تم الاضافة الى السلة`,
        size_color:`الحجم/اللون`,
        cancel:`اغلاق`,
        total: 'المجموع',
        lowestJO:`اقل اسعار في الاردن`,
        lowestSA:`اقل اسعار في السعودية`,
        dismiss:`اغلاق`,
        search:`بحث`,
        back:`الرئيسية`,
        tax:`شامل الضريبة`,

        //// nav
        start:`ابدأ الان`,
        emailSoftware:`الايميلات`,
        campaigns:`الحملات`,
        campaign:`حملة`,
        lists:`القوائم`,
        list:`قائمة`,
        templates:`القوالب`,
        template:`قالب`,
        accountCreated:`تم انشاء الحساب`,
        sent:`ارسال`,
        opened:`فتح`,
        clicked:`نقر`,
        lastChange:`اخر تحديث`,
        agent:`الموظف`,
        access:`الدخول`,
        userRole: `نوع المستخدم`,
        users: `مستخدمين`,
        user: `المستخدم العادي`,
        admin: `مدير`,
        superadmin: `مدير عام`,
        userEmail: `ايميل المستخدم`,
        userName: `اسم المستخدم`,
        close: `اغلاق`,
        position: `المسمى الوظيفي`,
        submitBtn: `تخزين`,
        reload: `اعادة تحميل`,
        subject: `العنوان`,
        fromName: `اسم المرسل`,
        fromEmail: `ايميل المرسل`,
        replyTo: `الردود الى`,
        lastUpdated: `اخر تحديث`,
        sendingActive: `جاري الارسال...`,
        inDraft: `مسودة`,
        alreadySent: `تم ارسال`,
        sendIconTooltip: `ارسال الان`,
        scheduleIconTooltip: `الارسال لاحقاً`,
        valid_email: `يرجى ادخال ايميل صحيح`,
        leads: `الطلبات`,
        subscribers: `ايميلات الملتحقين`,
        suppression: `منع الارسال`,
        offices: `المكاتب`,
        loginWithGoogle: `الدخول باستخدام Google`,
        register: `تسجيل`,
        forgetPassword: `نسيت كلمة السر؟`,
        sendResetEmail:`ارسال ايميل لاستعادة كلمة السر`,
        please_use_google_to_login: `يرجى استخدام Google لتسجيل الدخول`,
        user_not_found: `الحساب غير موجود`,
        listings: `العروض`,
        applicationsCenter: `مركز الطلبات`,
        cv: `السيرة الذاتية`,
        cvs: `السير الذاتية`,
        resume: `السيرة الذاتية`,
        resumes: `السير الذاتية`,
        coverletters: `الرسائل`,
        appsettings: `الاعدادات`,
        applications: `الطلبات`,
        inbox: `البريد`,
        corporate: `المؤسسة`,
        billing: `الفواتير`,
        receipts: `المدفوعات`,
        plan: `الخطة`,
        upgradePlan: `ترقية`,
        addToShortList: `اضافة الى المفضلة`,
        shortlists: `المفضلة`,
        note: `ملاحظة`,
        from: `من`,
        to: `الى`,
        latest: `الاحدث`,
        specialty: `الاختصاص`,
        city: `المدينة`,
        state: `الولاية`,
        verified: `تم التحقق منه`,
        prefer: `يفضل الايميل`,
        limit: `الحد الاعلى`,
        description: `وصف`,
        "cover-letters": `الرسائل`,



        
        /////////////
        captcha:`الرجاء ادخال الرمز الموجود في الصورة`,
        wrong_captcha: `لقد قمت بادخال رمز خطا`,
        only_numbers: `يسمح بأدخال الارقام فقط`,
        progressTitle: `التقدم`,
        pdfReportsIconTooltip: `انشاء تقرير`,
        generateReport: `انشاء تقرير`,
        clearChart: `اعادة تعيين`,
        cant_be_imported: `لا يمكن استيراد هذا الموظف`,
        domain_not_allowed: `هذا النطاق غير مسموح به`,
        allLegend: `مفتاح العتبات`,
        specialAccess: `اعطاء المستخدم صلاحيات لعرض صفحات الوزارة`,
        email: `الايميل`,
        password: `كلمة السر`,
        login: `تسجيل الدخول`,
        send: `ارسال`,
        home: `الرئيسية`,
        max_retries: `تخطيت عدد المحاولات المسموحة`,
        wrong_code: `خطأ في كتابة الرمز، يرجى المحاولة مرة اخرى`,
        expired: `انهت صلاحية الجلسة`,
        user_already_exists: `تم استيراد هذا المستخدم سابقاً`,
        success: `تمت العملية بنجاح`,
        not_found: `العنصر غير موجود`,
        all_fields_required: `جميع الحقول مطلوبة`,
        item_name_missing: `احد العناصر ينقصه اسم`,
        organisation: `نظرة عامة على الوزارة`,
        strategy: `استراتيجية الوزارة`,
        orgProgress: `مقياس انجاز الوزارة`,
        orgStructure: `الهيكل التنظيمي للوزارة`,
        userSettings: `اعدادات المستخدم`,
        mySpace: `المساحة الشخصية`,
        notifications: `الاشعارات`,
        progress: `الاهداف والمنجزات`,
        reports: `التقارير`,
        report: `تقرير`,
        managment: `لوحة التحكم`,
        employees: `استيراد الموظفين`,
        employeesSub: `ادارة الموظفين`,
        charts: `الرسم البياني للأداء`,
        approvals: `موافقاتي`,
        user: `حسابي`,
        orgStructureSetting: `تعديل الهيكل التنظيمي`,
        settings: `الاعدادات`,
        pubReports: `اصدار تقرير`,
        logout: `الخروج`,
        ////// progress
        search: `ابحث`,
        totalProgress:`اجمالي الانجازات`,
        goals:`الاهداف`,
        goal: `هدف`,
        keyResult:`نتيجة رئيسية`,
        keyResults:`نتائج رئيسية`,
        initiative:`مبادرة`,
        initiatives:`مبادرات`,
        trend: `التباين`,
        progress: `الاهداف والمنجزات الرئيسة`,
        mainResult: `النتائج/المبادرات`,
        status: `الحالة`,
        change: `تعديل`,
        editIconTooltip: `تعديل`,
        deleteIconTooltip: `مسح`,
        trending: {
          up:`تقدم`,
          down:`تراجع`,
          flat:`ثبات`
        },
        addGoal: `اضافة هدف`,
        addGoalTitle: `اضافة هدف`,
        editGoalTitle: `تعديل هدف`,
        parentGoal: `هدف علوي يرتبط به`,
        deleteGoalTitle: `مسح هدف`,
        addKeyResult: `اضافة نتيجة`,
        addKeyResultTitle: `اضافة نتيجة`,
        editKeyResultTitle: `تعديل نتيجة`,
        deleteKeyResultTitle: `مسح نتيجة`,
        addInit: `اضافة مبادرة`,
        addInitTitle: `اضافة مبادرة`,
        editInitTitle: `تعديل مبادرة`,
        deleteInitTitle: `مسح مبادرة`,
        viewGoalTitle: `تفاصيل الهدف`,
        viewKeyResultTitle: `تفاصيل النتيجة الرئيسية`,
        viewInitTitle: `تفاصيل المبادرة`,
        cancelBtn: `الغاء`,
        addBtn: `اضافة`,
        updateBtn: `تحديث`,
        add:`اضافة`,
        update:`تعديل`,
        delete:`مسح`,
        deleteMessage:`هل انت متأكد من ازالة هذا العنصر وكل ما يتعلق به بشكل نهائي؟`,
        startValue: `قيمة البداية`,
        targetValue: `قيمة الهدف`,
        unite: `وحدة القياس`,
        currentValue: `القيمة الحالية`,
        weight:`قيمة المساهمة`,
        quarter:`الربع`,
        meters: `متر`,
        dollar: `دولار امريكي`,
        riyal: `ريال سعودي`,
        rank: `ترتيب`,
        number: `عدد`,
        mbps: `Mbps`,
        gbps: `Gbps`,
        percentage:`%`,
        quarter_one: `الربع الاول`,
        quarter_two: `الربع الثاني`,
        quarter_three: `الربع الثالث`,
        quarter_four: `الربع الرابع`,
        required: `مطلوب`,
        upload: `تحميل ملف`,
        confidential: `سري`,
        normal: `عادي`,
        fileType: `نوع الملف`,
        files:`ملفات`,
        date: `التاريخ`,
        new: `جديد`,
        theVision: `الرؤية`,
        theMission: `المهمة`,
        theMessage: `الرسالة`,
        usersSettings: `اعدادات انواع المستخدمين`,
        updateUser: `تحديث المستخدم`,
        platformSettings: `اعدادات النظام`,
        thresholdSettings: `اعدادات العتبات`,
        uniteSettings: `اعدادات وحدات القياس`,
        fileTypeSettings: `اعدادات تصنيف سرية المستندات الداعمة`,
        allowedFilesSettings: `اعدادات انواع الملفات المسموحة`,
        AddThreshold:`اضافة عتبة`,
        name: `الاسم`,
        percentageWord: `النسبة`,
        saveSetting: `تخزين الاعدادات`,
        slug: `اسم التخزين (دون فراغ)`,
        arabic: `العربية`,
        english: `الانجليزية`,
        AddUnite: `اضافة وحدة`,
        hundredError: `يجب ان يكون اقل من 100`,
        AddFileType: `اضافة تصنيف`,
        
        
        addMainLevel: `اضافة منصب اساسي`,
        addBelow: `اضافة منصب فرعي`,
        phoneNumber: `رقم الهاتف`,
        importUser: `استيراد المستخدم وانشاء حساب`,
        employeeData: `معلومات الموظف`,
        employee: `الموظف`,
        accept: `موافقة`,
        type:`النوع`,
        reject: `رفض`,
        rejectMessage: `ملاحظة الرفض`,
        acceptedNotification: `تم الموافقة على`,
        rejectNotification: `تم رفض`,
        submissionNotification: `بحاجة للمراجعة`,
        deletionNotification: `تم مسح`,
        pending: `بانتظار المراجعة`,
        approved: `حصل على الموافقة`,
        there:`هناك`,
        createNewReport: `انشاء تقرير جديد`,
        dones: `انجازات`,
        todos: `خطط`,
        supports: `بحاجة دعم`,
        comments: `تعليقات`,
        reportName: `اسم التقرير`,
        fromDate: `من تاريخ`,
        toDate: `الى تاريخ`,
        newReportNotification: `تقرير جديد بعنوان`,
        by: `بواسطة`,
        submitReport: `تقديم التقرير`,
        deleteReport: `مسح التقرير`,
        updateReport: `تحديث التقرير`,
        uniteThresholds: `توحيد الاعدادات لجميع الفئات من المنجزات`,
        myReports: `تقاريري`,
        employeesReports: `تقارير الموظفين`,
        all: `الكل`,
        totals: `اجمالي الانجازات`,
        stepName: `اسم العتبة`,
        stepSettings: `اعدادات العتبة`,
        instituteSettings: `تعديل استراتيجية الوزارة`,
        lastSeen: `اخر تحديث`,
        getEmailNotifications: `الحصول على تنبيهات الأهداف و المنجزات و المبادرات عبر الايميل`,
        getReportsByEmail: `الحصول على تنبيهات التقارير عبر الايميل`,
        user_does_not_exist: `هذا المستخدم غير موجود`,
        viewIconTooltip: `الاطلاع على التفاصيل`,
        reportsIconTooltip: `الاطلاع على التقارير`,
        closeBtn: `اغلاق`,
        SMSCode: `الرمز المرسل في الرسالة النصية`,
        
      },
    },
    modelMenu: [
      {icon:'space_dashboard', text:'dashboard', path:'/dashboard', roles:['master','superadmin', 'admin', 'user']},
      {icon:'quiz', text:'surveys', path:'/surveys', roles:['master','superadmin', 'admin']},
      {icon:'bar_chart', text:'results', path:'/results', roles:['master','superadmin', 'admin']},
      {icon:'group', text:'UserResults', path:'/users-results', roles:['master','superadmin', 'admin']},
      {icon:'show_chart', text:'QuestionsResults', path:'/questions-results', roles:['master','superadmin', 'admin']},
      // {icon:'quiz', text:'survey', path:'/pre-survey', roles:['master','superadmin', 'admin', 'user']},
      // {icon:'workspace_premium', text:'certificates', path:'/certificates', roles:['master','superadmin']},
      // {
      //   icon: 'business',
      //   text: 'account',
      //   path: '/account',
      //   roles:['master', 'superadmin', 'admin', 'user'],
      //   subs:[
      //     {icon:'check_circle', text:'notifications', path:'/notifications', roles:['master','superadmin', 'admin', 'user']},
      //   ]
      // },
      // {
      //   icon: 'workspace_premium',
      //   text: 'certification',
      //   path: '/certification',
      //   roles:['master'],
      //   subs:[
      //     {icon:'auto_stories', text:'preMaterial', path:'/material', roles:['master','superadmin', 'admin', 'user']},
      //     {icon:'quiz', text:'survey_exam', path:'/survey_exam', roles:['master','superadmin', 'admin', 'user']},
      //     {icon:'quiz', text:'exam', path:'/exam', roles:['master','superadmin', 'admin', 'user']},
      //   ]
      // },
      {
        icon: 'settings',
        text: 'settings',
        path: '/settings',
        roles:['master', 'superadmin'],
        subs:[
          {icon:'people', text:'users', path:'/users', roles:['master', 'superadmin']},
          {icon:'supervised_user_circle', text:'accounts', path:'/accounts', roles:['master']},
        ]
      },
    ],
    menu:[],
    access_token: null,
    verification:false,
    online:false,
    centralLogin:false,
    globalLoading:false,
    user: null,
    users:[],
    uid:null,
    language:'en',
    notifications:[],
    settings: {
      baseUrl: ``,
      apiBase: ``,
      public: ``,
    },
    userRoles:[
      {slug:'master', text:'Master'},
      {slug:'superadmin', text:'Superadmin'},
      {slug:'admin', text:'Admin'},
      {slug:'user', text:'User'},
    ],
    loading: false,
    survey:null,
    
  },
  modules: {
  },
  getters: {
    
  },
  mutations: {
    unAuthLogout(state) {
      state.access_token = null
      state.user = null
      state.uid = null
      state.centralLogin = false
      state.menu = []
      state.globalLoading = []
      state.users = []
      state.notifications = []
      let noGate = ['home', 'login', 'register', 'toc', 'verify']
      // console.log(router.currentRoute.name)
      if(!noGate.includes(router.currentRoute.name)) {
        router.push('/home').catch(x => {})
      }
    },
    setOnline(state, payload) {
      state.online = payload == 1 ? true : false
    },
    updateNotifications(state, payload) {
      // let newNotifications = payload.filter(x=>!state.notifications.map(x=>x.id).includes(x.id))
      state.notifications = payload// [...newNotifications, ...state.notifications]
    },
    setBaseUrl(state, payload) {
      state.settings.baseUrl = `${payload.base}`
      state.settings.apiBase = `${payload.base}/api/public`
      state.settings.public = `${payload.base}/api/storage/app/`
    },
    setUser(state, payload){
      state.user = payload
    },
    loadLanguage(state) {
      return new Promise(async(res, rej)=>{
        if(localStorage.language) {
          state.language = localStorage.language
          if(state.language == 'ar') {
            Vue.prototype.$vuetify.rtl = true
          } else {
            Vue.prototype.$vuetify.rtl = false
          }
        } else {
            let language = 'ar'
            state.language = language
            localStorage.language = language
            if(language == 'ar') {
              Vue.prototype.$vuetify.rtl = true
            } else {
              Vue.prototype.$vuetify.rtl = false
            }
        }
        res(true)
      })
    },
  },
  actions: {
    setSettingsBase({ commit, dispatch, state }, payload){
      return new Promise(async (resolve, reject) => {
        commit('setBaseUrl', payload)
        resolve(true)
      })
    },
    getUser({ commit, dispatch, state }) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.get(`${state.settings.apiBase}/user/me`)
          .then(x => x.data).catch(error=>{})
        // console.log(a)
        await commit('setUser', a)
        resolve(a)
      })
    },
    checkAuth({ commit, dispatch, state }) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          commit('unAuthLogout')
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.get(`${state.settings.apiBase}/user/check`)
          .then(x => x.data).catch(error=>{
            // console.log(error)
          })
        resolve(a)
      })
    },
    logout({ commit, dispatch, state }) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          // assume your access token is stored in local storage 
          // (it should really be somewhere more secure but I digress for simplicity)
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          // Do something with request error
          return Promise.reject(error);
        })
        try {
          let a = await axios.get(`${state.settings.apiBase}/logout`)
          .then(x => x.data)
          resolve(a)
        } catch (error) {
          resolve(false)
        }
      })
    },
    postResource({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.post(`${state.settings.apiBase}${payload.resource}`, {...payload.value})
          .then(x => x.data).catch(error=>{})
          // console.log(a)
          resolve(a)
      })
    },
    getResource({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.get(`${state.settings.apiBase}${payload.resource}${payload.page?payload.page:''}`)
          .then(x => x.data).catch(error=>{})
          // console.log(a)
          resolve(a)
      })
    },
    searchResource ({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios
        .post(`https://yervip.com/back/public/api/v1/ninjasearch/${state.country.alpha2Code}${payload.page?payload.page:''}`, {value:state.search})
        .then(x => x.data).catch(error=>{})
        router.push(`/search`).catch(x=>{});
        state.products = []
        let prod = await commit('loadProducts', a.data)
        state.search_resource = a
        resolve(a)
      })
    },
    putResource({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.put(`${state.settings.apiBase}${payload.resource}`, {...payload.value})
          .then(x => x.data).catch(error=>{})
          // console.log(a)
          resolve(a)
      })
    },
    deleteResource({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.delete(`${state.settings.apiBase}${payload.resource}`)
          .then(x => x.data).catch(error=>{})
          // console.log(a)
          resolve(a)
      })
    },
    getNotifications({ commit, dispatch, state }) {
      return new Promise(async (resolve, reject) => {
        headers.Authorization = `Bearer ${state.access_token}`
        axios.interceptors.request.use(function (config) {
          let token = state.access_token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config;
        }, function (error) {
          return Promise.reject(error);
        })
        axios.interceptors.response.use(async response => {
          return response;
        }, async error => {
          if (error.response.status === 401) {
            commit('unAuthLogout')
          }
          return error;
        });
        let a = await axios.get(`${state.settings.apiBase}/notifications-mini`)
          .then(x => x.data).catch(error=>{})
        await commit('updateNotifications', a)

        resolve(a)
      })
    },
  },
});
