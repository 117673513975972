<template>
  <SurveryLayout>
    <v-card NotificationPanel :loading="loading">
      <v-card-text class="py-0">
        <v-row>
          <v-col cols="12">
            <v-row class="align-center">
              <v-col cols="2" class="text-center" TITLE>
                <v-icon size="40" color="primary">quiz</v-icon>
              </v-col>
              <v-col cols="10" TITLE>
                <div :class="`${arabic?'arFont':'enFont'} black--text`">
                    {{i18n.instructions_title}}
                </div>
                <div :class="`${arabic?'arFont':'enFont'} grey--text`">
                    {{i18n.instructions_subtitle}}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="align-center" v-if="!done">
              <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" TIME>
                <v-row class="align-center">
                  <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
                    <v-icon>alarm</v-icon>
                  </v-col>
                  <v-col cols="10" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                    {{i18n.time_is_open}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" TIME>
                <v-row class="align-center">
                  <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
                    <v-icon>mood</v-icon>
                  </v-col>
                  <v-col cols="10" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                    {{i18n.stop_finish_later}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" TIME>
                <v-row class="align-center">
                  <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
                    <v-icon>phone_iphone</v-icon>
                  </v-col>
                  <v-col cols="10" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                    {{i18n.use_pc_or_phone}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" ANSWERS>
                <v-row class="align-center">
                  <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
                    <v-icon>radio_button_checked</v-icon>
                  </v-col>
                  <v-col cols="10" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                    <div :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                      {{i18n.survey_instruction_intro}}
                    </div>
                    <div :class="`${arabic?'arFont':'enFont'} subtitle-1 pt-2`">
                      <v-icon size="20">radio_button_checked</v-icon>
                      {{i18n.to_almost_no_extent}}
                      <v-rating
                        empty-icon="star_outline"
                        full-icon="star"
                        half-icon="star_half"
                        length="5"
                        size="20"
                        :value="1"
                      ></v-rating>
                    </div>
                    <div :class="`${arabic?'arFont':'enFont'} subtitle-1 pt-2`">
                      <v-icon size="20">radio_button_checked</v-icon>
                      {{i18n.to_a_slight_extent}}
                      <v-rating
                        empty-icon="star_outline"
                        full-icon="star"
                        half-icon="star_half"
                        length="5"
                        size="20"
                        :value="2"
                      ></v-rating>
                    </div>
                    <div :class="`${arabic?'arFont':'enFont'} subtitle-1 pt-2`">
                      <v-icon size="20">radio_button_checked</v-icon>
                      {{i18n.to_a_moderate_extent}}
                      <v-rating
                        empty-icon="star_outline"
                        full-icon="star"
                        half-icon="star_half"
                        length="5"
                        size="20"
                        :value="3"
                      ></v-rating>
                    </div>
                    <div :class="`${arabic?'arFont':'enFont'} subtitle-1 pt-2`">
                      <v-icon size="20">radio_button_checked</v-icon>
                      {{i18n.to_great_extent}}
                      <v-rating
                        empty-icon="star_outline"
                        full-icon="star"
                        half-icon="star_half"
                        length="5"
                        size="20"
                        :value="4"
                      ></v-rating>
                    </div>
                    <div :class="`${arabic?'arFont':'enFont'} subtitle-1 pt-2`">
                      <v-icon size="20">radio_button_checked</v-icon>
                      {{i18n.to_very_great_extent}}
                      <v-rating
                        empty-icon="star_outline"
                        full-icon="star"
                        half-icon="star_half"
                        length="5"
                        size="20"
                        :value="5"
                      ></v-rating>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="align-center" v-if="done">
              <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" TIME>
                <v-row class="align-center">
                  <v-col cols="12" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
                    {{i18n.survey_done_check_result}}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="!done">
            <v-checkbox 
            :class="`${arabic?'arFont':'enFont'}`" 
            :label="i18n.agree_to_instructions" 
            v-model="toc" 
            :value="true"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!done">
        <v-btn 
          :class="`${arabic?'arFont':'enFont'}`" 
          x-large
          block
          @click="action('start')"
          color="primary"
          :disabled="!toc"
        >
        {{i18n.start_survey}}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="done">
        <v-btn 
          :class="`${arabic?'arFont':'enFont'}`" 
          x-large
          block
          @click="action('results')"
          color="primary"
        >
        {{i18n.survey_result}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </SurveryLayout>
</template>
<script>
import axios from 'axios';
import SurveryLayout from './SurveryLayout'
const headers = {'Accept': 'application/json'};

export default {
  components: {SurveryLayout},
  data() {
    return {
      loading:false,
      dialog:false,
      open:[],
      toc:false,
      result:null,
      done:false,
    }
  },
  methods: {
    
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },

    action(action){
      this.$emit(action)
    }
    
  },
  computed:{
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
  watch:{
    
  },
  async created(){
  },
}
</script>

<style>
.flagBtn {
  height: 80px !important;
}
</style>