<template>
  <v-row class="my-5">
    <v-col cols="12">
      <h2>{{i18n.stats}}</h2>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="statCard">
        <v-card-text>
          <div style="font-size:32px;" class="py-2">
            {{ resource.percentageAnswered.toFixed(1) }}%
            <v-progress-circular 
              :size="30"
              :width="5"
              color="primary"
              :value="resource.percentageAnswered">
            </v-progress-circular>
          </div>
          <div style="font-weight:bold;">
            {{i18n['percentage_answered_questions']}}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="statCard">
        <v-card-text>
          <div style="font-size:32px;" class="py-2">
            {{ resource.averageRating.toFixed(1) }} <span style="font-size:14px;">/ 5</span>
            <v-progress-linear
              color="primary"
              height="10"
              :value="resource.averageRating*20"
              striped
              rounded
            ></v-progress-linear>
          </div>
          <div style="font-weight:bold;">
            {{i18n['overall_average_rating']}}
          </div>
          {{i18n.from}} {{resource.totalRateResponses}} {{i18n.responses}}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="statCard">
        <v-card-text>
          <div class="" style="font-size:22px;">
            {{i18n.started}}
          </div>
          <div>{{resource.started_at}}</div>
          <div class="" style="font-size:22px;">
            {{i18n.ended}}
          </div>
          <div>{{resource.ended_at}}</div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props:['resource'],
  computed:{
    checkDisabled(){
      if(this.user.role == 'master') {
        return false
      } else {
        return this.resource.tokens > 0 ? false : true
      }
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
}
</script>