<template>
  <v-row>
    <v-col cols="12">
      <h2>{{i18n.responses}}</h2>
    </v-col>
    <v-col cols="12" v-for="(response, r) in responses" :key="'responses-'+r">
      <v-row>
        <v-col style="border-bottom:1px solid #efefef;">
          <h3>{{ response.survey_question.label }}</h3>
        </v-col>
        <v-col style="border-bottom:1px solid #efefef;">
          <span v-if="response.survey_question.type === 'multiple'">
            {{ response.survey_question_option_id }}
          </span>
          <span v-if="response.survey_question.type === 'text'">
            {{response.value}}
          </span>
          <span v-if="response.survey_question.type === 'rate'" class="text-center">
            <v-rating
              empty-icon="star_outline"
              full-icon="star"
              length="5"
              size="20"
              :value="response.rating"
            ></v-rating>
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props:['responses'],
  computed:{
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    language(){
      return this.$store.state.language
    },
  }
}
</script>
