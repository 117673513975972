<template>
  <v-row class="align-center">
    <v-col cols="12" FIELD @click="goTo('/notifications')"
      v-for="(item, i) in notifications" :key="i +'notification'" 
      class="elevation-1 mb-1" 
      :style="`${item.read ? 'background:#fff;' : 'background:#d4e6f8;'}cursor:pointer;`">
      <v-row class="align-center" >
        <v-col cols="1" class="text-left py-0" style="text-transform:uppercase;font-size:9px;">
          <v-icon>notifications_active</v-icon>
        </v-col>
        <v-col cols="11" class="py-0 px-6">
          <v-row class="align-center">
            <v-col cols="12" class="py-0" style="text-transform:uppercase;font-size:9px;">
              notification {{getFormat(item.created_at)}}
            </v-col>
            <v-col cols="12" :class="`${arabic?'arFont':'enFont'} py-0`">
              {{item.content}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" FIELD v-if="!notifications.length" :class="`${arabic?'arFont':'enFont'} py-12 text-center`">
      <v-icon size="60" color="grey lighten-1">inbox</v-icon>
      <div :class="`${arabic?'arFont':'enFont'}`">{{i18n.no_notifications}}</div>
    </v-col>
  </v-row>
</template>
<script>

import { format, render, cancel, register } from 'timeago.js';
export default {
  props:['notifications'],
  data() {
    return {
      loading:false,
    }
  },
  
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    getFormat(d){
      return format(d)
    },
  },
  computed:{
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
  },
  mounted(){
    
  }
}
</script>
