<template>
  <v-row class="align-center pt-10 white text-center">
    <v-col cols="12" LOADING v-if="!loading && user && user.account && user.account.logo" class="text-center my-10">
      <img 
        v-if="site && site.name && site.logo" 
        :src="`${isLogin? site.logo : site.wlogo}`" 
        alt="site.name" 
        style="width:100px;cursor:pointer;">
        <span v-if="user && user.account && user.account.logo" class="mx-2 secondary secondary--text">.</span>
        <img 
        class="mx-2"
        v-if="user && user.account && user.account.logo" 
        :src="`${user.account.logo}`" 
        alt="site.name" 
        style="height:25px;cursor:pointer;">
    </v-col>
    <v-col cols="12" v-if="user && user.name">
      <v-row class="align-center">
        <v-col cols="12" :class="`${arabic?'arFont':'enFont'} text-center primary--text`" style="font-size:26px;">
          {{ user.name }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row class="align-center">
        <v-col cols="6" :class="`${arabic?'arFont':'enFont'} text-center primary--text`" style="font-size:26px;">
          النتيجة النهائية
        </v-col>
        <v-col cols="6" :class="`${arabic?'arFont':'enFont'} text-center secondary--text`" style="font-size:26px;" v-if="!loading">
          {{result.total}} نقطة
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4" class="forceArabic d-print-none">
    </v-col>
    <v-col cols="12" md="4" class="forceArabic text-center">
      <innovationRadar :small="true" :data="chartData" />
    </v-col>
    <v-col cols="12" md="4" class="forceArabic d-print-none">
    </v-col>
    <v-col cols="12" class="forceArabic" v-if="false">
      <mindmap
        :nodes="nodes"
        :connections="connections"
        :editable="true"
      />
    </v-col>
    <v-col cols="12" class="forceArabic text-center" v-if="result && result.map">
      <img :src="`/images/results/map_r_${result.map}.png`" width="97%" alt="">
    </v-col>
    <v-col cols="12" class="forceArabic text-center" v-if="result && result.table">
      <img :src="`/images/results/table_r_${result.table}.png`" width="70%" alt="">
    </v-col>
    <v-col cols="12">
      <v-row class="align-center">
        <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`" TIME>
          <v-row class="align-center">
            <v-col cols="2" :class="`${arabic?'arFont':'enFont'} text-center`">
            </v-col>
            <v-col cols="10" :class="`${arabic?'arFont':'enFont'} subtitle-1`">
              
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>
    </v-col>
    <v-col cols="12">
      
    </v-col>
  </v-row>
</template>
<script>
import innovationRadar from '@/components/innovationRadar'
import {map} from '@/map'
import axios from 'axios';
const headers = {'Accept': 'application/json'};
export default {
  components: {innovationRadar},
  data() {
    return {
      loading:false,
      dialog:false,
      open:[],
      result:null,
      ...map,
      chartData:{
        labels: [
          'الاستمرار في تطوير المهارات' , //
          'الابداع', //
          'حل المشاكل', //
          'تقييم المخاطر',
          'المخاطرة',
          'بناء العلاقات' , 
          'التواصل',
          'التطبيق'
        ],
        // labels: [
        //     'Creativity', // 1
        //     'Problem-Solving', // 1
        //     'Continuous Improvement Skills', // 1
        //     'Risk Assessment', // 2
        //     'Risk-Taking Skills', // 2
        //     'Relationshipâ€“Building', // 3
        //     'Communication Skills', // 3
        //     'Implementation Skills' // 4
        // ],
        datasets: [{
            label: 'خريطة الابتكار',
            data: [20, 10, 18, 30, 22, 13, 28, 15],
            backgroundColor: 'rgba(2, 61, 102, 0.75)', // 'rgba(255, 99, 132, 0.8)',
            borderColor: 'rgb(2, 61, 102)',
            pointBackgroundColor: '#023d66',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
        }],
      },
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    print(){
      window.print();
    },
    async loadHome(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        let r = await this.$store.dispatch('getResource', {resource:'/user-answers'})
        console.log(r)
        this.result = r
        this.chartData.datasets[0].data = r.result
        this.loading = false
        res(true)
      })
    }
    
  },
  computed:{
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
  watch:{
    
  },
  async created(){
    await this.loadHome()
  },
}
</script>

<style>
.forceArabic * {
  font-family: 'Tajawal', sans-serif !important;
  letter-spacing: unset !important;
  font-size: 0.9rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
}
.mindmap-subnode-group *,
.mindmap-subnode-group {
  white-space: nowrap!important;
  font-size: 0.5rem!important;
}
</style>