<template>
  <SurveryLayout>
    <v-card :loading="loading">
      <v-card-text class="py-0" v-if="!loading">
        <v-row>
          <v-col cols="12">
            <v-row class="align-center">
              <v-col cols="2" class="text-center" TITLE>
                <v-icon size="40" color="primary">quiz</v-icon>
              </v-col>
              <v-col cols="10" TITLE>
                <h2 :class="`${arabic?'arFont':'enFont'} black--text`">
                  {{survey.name}}
                </h2>
                {{i18n.progress}}: {{answered_questions}}/{{total_questions}} ({{progress}})
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <SurveryQuestion 
              :question="visible_questions[visible_index]" 
              @nextQuestion="nextQuestion" 
              :questionsCount="visible_questions.length" 
            />
          </v-col>
          <v-col cols="12" v-if="!visible_questions.length">
            <v-row>
              <v-col cols="12" class="text-center py-12">
                <h1>
                  {{i18n.already_done}}
                </h1>
              </v-col>
            </v-row>

            <v-btn 
              :class="`${arabic?'arFont':'enFont'}`" 
              x-large
              block
              @click="action('goToDashboard')"
              color="primary"
            >
            {{i18n.dashboard}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </SurveryLayout>
</template>
<script>
import axios from 'axios';
import SurveryLayout from './SurveryLayout'
import SurveryQuestion from './SurveryQuestion'
const headers = {'Accept': 'application/json'};

export default {
  props:['loading'],
  components: {SurveryLayout, SurveryQuestion},
  data() {
    return {
      dialog:false,
      open:[],
      toc:false,
      result:null,
      done:false,
      visible_index:0,
      error:null,
    }
  },
  methods: {
    isInt(value) {
      return !isNaN(value) && 
            parseInt(Number(value)) == value && 
            !isNaN(parseInt(value, 10));
    },
    getLocation() {
      return new Promise(async (res, rej)=>{
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position)=> res(position.coords), 
            function error(msg) {alert('Please enable your GPS position feature.');},
            {enableHighAccuracy: true}
          );
        } else {
          this.error = "Geolocation is not supported by this browser.";
        }
      })
    },
    async nextQuestion(value){
      const {latitude, longitude} = await this.getLocation()
      console.log({latitude, longitude})
      console.log(this.visible_questions[this.visible_index])
      console.log(value)
      const question = this.visible_questions[this.visible_index]
      const data = {
        survey_id: question.survey_id,
        latitude,
        longitude,
        survey_question_id: question.id,
        survey_question_option_id: question.type === 'multiple'? value : null,
        rating: question.type === 'rate'? value : null,
        value: value
      }
      await this.submitResponse(data)
    },
    async submitResponse(data){
      await this.$store.dispatch('postResource', {resource:'/survey-responses', value:{...data}})
      this.action('reload')
    },
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },

    action(action){
      this.$emit(action)
    }
    
  },
  computed:{
    progress() {
      return `${(this.answered_questions / this.total_questions * 100).toFixed(1)}%`
    },
    total_questions() {
      return this.$store.state.survey && this.$store.state.survey.survey_questions.length
    },
    answered_questions(){
      return this.$store.state.survey && this.$store.state.survey.survey_questions
      .filter(x=>x.user_survey_question_response).length
    },
    visible_questions(){
      return this.$store.state.survey.survey_questions
      .filter(x=>!x.user_survey_question_response)
    },
    survey(){
      return this.$store.state.survey
    },
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
  watch:{
    
  },
  async created(){
  },
}
</script>

<style>
.flagBtn {
  height: 80px !important;
}
</style>